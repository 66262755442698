import { Button, CircularProgress } from "@mui/material";
import { FC, MutableRefObject, useState } from "react";
import { PiMicrosoftExcelLogo } from "react-icons/pi";
import { useGridViewExport } from "../../../hooks/useGridViewExport";
import { GridApiPro } from "@mui/x-data-grid-pro";
import toast from "react-hot-toast";

export interface ExportGridDataProps {
  apiPath: "orders" | "articles" | "customers" | "supplier";
  gridApiRef: MutableRefObject<GridApiPro>;
  orderStatus?: "Open" | "Closed"; //to refactor later
}
export const ExportGridData: FC<ExportGridDataProps> = ({
  gridApiRef,
  apiPath,
  orderStatus,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { exportMutation } = useGridViewExport(
    apiPath,
    gridApiRef,
    orderStatus
  );
  const handleClickOpen = async () => {
    setIsLoading(true);
    try {
      await exportMutation.mutateAsync();
      toast.success("Ansicht erfolgreich exportiert");
    } catch (e) {
      console.error(e);
      toast.error("Fehler beim Exportieren der Ansicht");
    }
    setIsLoading(false);
  };
  return (
    <Button
      variant="outlined"
      startIcon={
        isLoading ? <CircularProgress size={16} /> : <PiMicrosoftExcelLogo />
      }
      sx={{ height: 33 }}
      disabled={isLoading}
      onClick={() => handleClickOpen()}
    >
      Ansicht exportieren
    </Button>
  );
};
