import {
  getGridBooleanOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  getGridStringOperators,
  GridColumnVisibilityModel,
  GridFilterModel,
  GridLogicOperator,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { SetURLSearchParams } from "react-router-dom";
export const FILTER_SEARCH_PARAM = "filter";
export const HIDDEN_COLUMNS_SEARCH_PARAM = "hiddenColumns";
export const SORTING_SEARCH_PARAM = "sorting";
export const VIEW_SEARCH_PARAM = "viewId";

export const useGridStateURL = (
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams
) => {
  const persistGridFilterStateInUrl = (
    filterModel: GridFilterModel | undefined
  ) => {
    const filterItemsParamString = compressFilterModel(filterModel);
    setSearchParams(
      (prev) => {
        if (!!filterItemsParamString) {
          prev.set(FILTER_SEARCH_PARAM, filterItemsParamString);
        } else if (prev.has(FILTER_SEARCH_PARAM)) {
          prev.delete(FILTER_SEARCH_PARAM);
        }
        return prev;
      },
      { replace: true }
    );
  };
  const persistGridSortStateInUrl = (sortModel: GridSortModel | undefined) => {
    const sortingParamString = sortModel
      ?.map((item) => {
        return `${item.field}::${item.sort}`;
      })
      .join(",");
    setSearchParams(
      (prev) => {
        if (!!sortingParamString) {
          prev.set(SORTING_SEARCH_PARAM, sortingParamString!);
        } else if (prev.has(SORTING_SEARCH_PARAM)) {
          prev.delete(SORTING_SEARCH_PARAM);
        }
        return prev;
      },
      { replace: true }
    );
  };
  const persistGridColumnsStateInUrl = (
    columnVisibilityModel: GridColumnVisibilityModel | undefined
  ) => {
    const hiddenColumns = Object.keys(columnVisibilityModel ?? {}).filter(
      (key) => !columnVisibilityModel?.[key]
    );
    const visibleColumnsParamString = hiddenColumns.join(",");
    setSearchParams(
      (prev) => {
        if (!!visibleColumnsParamString) {
          prev.set(HIDDEN_COLUMNS_SEARCH_PARAM, visibleColumnsParamString);
        } else if (prev.has(HIDDEN_COLUMNS_SEARCH_PARAM)) {
          prev.delete(HIDDEN_COLUMNS_SEARCH_PARAM);
        }
        return prev;
      },
      { replace: true }
    );
  };

  const getGridStateFromUrl = ():
    | {
        sortModel?: GridSortModel;
        filterModel?: GridFilterModel;
        columnVisibilityModel?: GridColumnVisibilityModel;
      }
    | undefined => {
    const filterParam = searchParams.get(FILTER_SEARCH_PARAM);

    const filterModel: GridFilterModel | undefined =
      parseCompressedFilterModel(filterParam);
    const columnsParam = searchParams.get(HIDDEN_COLUMNS_SEARCH_PARAM);
    let columnVisibilityModel: GridColumnVisibilityModel | undefined =
      undefined;
    const columnsValues =
      columnsParam && columnsParam != "" ? columnsParam.split(",") : null;
    columnVisibilityModel =
      columnsValues && columnsValues.length > 0
        ? columnsValues.reduce((acc, column) => {
            acc[column] = false;
            return acc;
          }, {} as GridColumnVisibilityModel)
        : undefined;

    const sortingParamString = searchParams.get(SORTING_SEARCH_PARAM);
    const sortingValues =
      sortingParamString && sortingParamString != ""
        ? sortingParamString.split("::")
        : null;
    const sortItem: GridSortItem | undefined = sortingValues
      ? { field: sortingValues[0], sort: sortingValues[1] as GridSortDirection }
      : undefined;
    const sortModel: GridSortModel | undefined = sortItem
      ? [sortItem]
      : undefined;
    return !!filterModel || !!columnVisibilityModel || !!sortModel
      ? {
          ...(filterModel ? { filterModel } : {}),
          ...(columnVisibilityModel ? { columnVisibilityModel } : {}),
          ...(sortModel ? { sortModel } : {}),
        }
      : undefined;
  };

  const clearGridStateInUrl = () => {
    setSearchParams(
      (prev) => {
        if (prev.has(FILTER_SEARCH_PARAM)) prev.delete(FILTER_SEARCH_PARAM);
        if (prev.has(HIDDEN_COLUMNS_SEARCH_PARAM))
          prev.delete(HIDDEN_COLUMNS_SEARCH_PARAM);
        if (prev.has(SORTING_SEARCH_PARAM)) prev.delete(SORTING_SEARCH_PARAM);
        return prev;
      },
      { replace: true }
    );
  };
  const getGridViewIdFromUrl = () => {
    return searchParams.get(VIEW_SEARCH_PARAM);
  };
  const setGridViewIdInUrl = (viewId: string | undefined) => {
    setSearchParams(
      (prev) => {
        if (!!viewId) {
          prev.set(VIEW_SEARCH_PARAM, viewId);
        } else if (prev.has(VIEW_SEARCH_PARAM)) {
          prev.delete(VIEW_SEARCH_PARAM);
        }
        return prev;
      },
      { replace: true }
    );
  };
  return {
    // persistGridStateInUrl,
    persistGridFilterStateInUrl,
    persistGridSortStateInUrl,
    persistGridColumnsStateInUrl,
    getGridStateFromUrl,
    clearGridStateInUrl,
    getGridViewIdFromUrl,
    setGridViewIdInUrl,
  };
};
const compressFilterModel = (
  filterModel: GridFilterModel | undefined
): string | null => {
  if (filterModel && filterModel.items && filterModel.items.length > 0) {
    const compressedObject = {
      o: filterModel.logicOperator,
      i: [] as { [key: string]: { [key: string]: any } }[],
    };
    for (const item of filterModel.items) {
      compressedObject.i.push({
        [item.field]: { [item.operator]: item.value ?? "" },
      });
    }
    return JSON.stringify(compressedObject);
  }
  return null;
};
const parseCompressedFilterModel = (
  compressedFilterModelString: string | undefined | null
): GridFilterModel | undefined => {
  let compressedModel: CompressedFilterModel | undefined = undefined;
  if (compressedFilterModelString == null) return undefined;
  try {
    compressedModel = JSON.parse(compressedFilterModelString!);
  } catch (e) {
    console.error("Failed to parse the filter string");
    return undefined;
  }
  if (compressedModel == null) return undefined;
  let filterModel: GridFilterModel = {
    items: [],
    logicOperator: compressedModel.o,
  };
  try {
    for (const item of compressedModel.i) {
      const field = Object.keys(item)[0];
      const operator = Object.keys(item[field])[0];
      let value = item[field][operator];
      value = value === "" ? undefined : value;
      filterModel.items.push({
        field,
        operator,
        value,
      });
    }
    return filterModel;
  } catch (error) {
    console.error("Failed to parse compressed filter", error);
  }
};
export interface CompressedFilterModel {
  o: GridLogicOperator;
  i: [{ [key: string]: { [key: string]: any } }];
}
