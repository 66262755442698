export const ORDERS_VIEWS_CONFIG: OrderGridView[] = [
  {
    id: "allItems",
    displayName: "Alle",
    hiddenColumns: [],
  },
  {
    id: "tracking",
    displayName: "Nachverfolgung",
    hiddenColumns: [
      "articleNumber",
      "articleName",
      "amount",
      "senderName",
      "recipientName",
      "carrierName",
      "sdBeleg",
      "pspElement",
      "versorgungsNummer",
      "handlingUnitCount",
      "weightBrutto",
      "packagingStage",
    ],
  },
  {
    id: "articleInfo",
    displayName: "Artikelinfo",
    hiddenColumns: [
      "orderState",
      "lieferscheinNummer",
      "senderName",
      "recipientName",
      "carrierName",
      "sdBeleg",
      "pspElement",
      "orderDate",
      "finishDate",
      "pickUpDate",
      "idGoodsIssued",
      "modifiedAt",
      "modifiedUser",
      "createdAt",
      "createdUser",
    ],
  },
];
export interface OrderGridView {
  id: string;
  displayName: string;
  hiddenColumns: string[];
}
