export const MASTER_DATA_BOOK_VIEWS_CONFIG: MasterDataGridView[] = [
  {
    id: "allItems",
    displayName: "Alle",
    hiddenColumns: [],
  },
];
export interface MasterDataGridView {
  id: string;
  displayName: string;
  hiddenColumns: string[];
}
