import React, { useState, useEffect } from "react";

// MUI Components
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
  deDE,
  GridRowModesModel,
  GridRowModes,
  GridRowId,
  GridRowModel,
  GridValidRowModel,
  useGridApiRef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Component
import MasterDataModal from "../../components/masterDataModal/masterDataModal";
import DeletionModal from "../../components/deletionModal/deletionModal";
import toast, { Toaster } from "react-hot-toast";

// Styles
import "./masterData.css";

// Icons
import SyncIcon from "@mui/icons-material/Sync";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import GridOnRoundedIcon from "@mui/icons-material/GridOnRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import { useGridViews } from "../../hooks/useGridViews";
import { MASTER_DATA_BOOK_VIEWS_CONFIG } from "./configurations/viewsConfig";
import { ExportGridData } from "../../components/common/export/ExportGridData";

const MasterData = (props: any) => {
  const [isMasterDataModalVisible, setIsMasterDataModalVisible] =
    useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBulkeditActive, setIsBulkeditActive] = useState(false);

  const {
    views,
    changeViewHandler,
    changeColumnVisibilityHandler,
    changeFilterHandler,
    changeSortingHandler,
    controlledFilterModel,
    controlledSortModel,
    controlledVisibleColumns,
  } = useGridViews(MASTER_DATA_BOOK_VIEWS_CONFIG);
  const activeView = views.find((view) => view.active);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  function openDropdown(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const errorToast = (message: any) => toast.error(message);
  const successToast = (message: any) => toast.success(message);

  const toastListener = (message: any) => {
    if (message === "success") {
      successToast("Daten gespeichert");
    } else if (message === "error") {
      errorToast("Etwas ist fehlgeschlagen");
    } else if (message === "deletion") {
      successToast("Löschen erfolgreich");
    } else if (message === "update") {
      successToast("Daten aktualisiert");
    }
  };

  const [areCustomerColumnsVisible, setAreCustomerColumnsVisible] =
    useState<GridColumnVisibilityModel>({
      ident: true,
      customerName: true,
      addressLine1: true,
      city: true,
      postcode: true,
      countryName: true,
      modifiedTime: true,
      modifiedUser: true,
      createdTime: true,
      createdUser: true,
    });

  const [areArticleColumnsVisible, setAreArticleColumnsVisible] =
    useState<GridColumnVisibilityModel>({
      ident: true,
      articleNumber: true,
      name: true,
      modifiedTime: true,
      modifiedUser: true,
      createdTime: true,
      createdUser: true,
    });

  const [areAddressColumnsVisible, setAreAddressColumnsVisible] =
    useState<GridColumnVisibilityModel>({
      ident: true,
      addressLine1: true,
      city: true,
      postcode: true,
      countryName: true,
      modifiedTime: true,
      modifiedUser: true,
      createdTime: true,
      createdUser: true,
    });

  const [areCarrierColumnsVisible, setAreCarrierColumnsVisible] =
    useState<GridColumnVisibilityModel>({
      ident: true,
      carrierName: true,
      addressLine1: true,
      city: true,
      postcode: true,
      countryName: true,
      modifiedTime: true,
      modifiedUser: true,
      createdTime: true,
      createdUser: true,
    });

  const [customer, setCustomer] = useState({
    id: "",
    ident: "",
    name: "",
    addressId: "",
    addressLine1: "",
    city: "",
    postcode: "",
    countryId: "",
    countryName: "",
    modifiedTime: "",
    modifiedUser: "",
    createdTime: "",
    createdUser: "",
  });

  const [article, setArticle] = useState({
    id: "",
    ident: "",
    articleNumber: "",
    name: "",
    modifiedTime: "",
    modifiedUser: "",
    createdTime: "",
    createdUser: "",
  });

  const [address, setAddress] = useState({
    id: "",
    ident: "",
    addressLine1: "",
    city: "",
    postcode: "",
    country: "",
    modifiedTime: "",
    modifiedUser: "",
    createdTime: "",
    createdUser: "",
  });

  const [carrier, setCarrier] = useState({
    id: "",
    ident: "",
    name: "",
    addressId: "",
    addressLine1: "",
    city: "",
    postcode: "",
    countryId: "",
    countryName: "",
    modifiedTime: "",
    modifiedUser: "",
    createdTime: "",
    createdUser: "",
  });

  const customerColumns: GridColDef[] = [
    {
      field: "ident",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      type: "text",
      minWidth: 80,
      headerAlign: "left",
      renderCell: (params) => {
        // Check if the cell is in edit mode
        if (
          !isBulkeditActive &&
          (props.user.role.permissions === "ADMIN" ||
            props.user.role.permissions === "WRITE")
        ) {
          return (
            <span
              style={{
                cursor: "pointer", // Makes the cell content appear clickable
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = "none"; // Remove underline when hover ends
              }}
              onClick={() => {
                const rowIndex = rows.findIndex(
                  (item) => item.id === params.id
                );
                editItem(rowIndex);
              }}
            >
              {params.value}
            </span>
          );
        } else {
          return (
            <span
              style={{
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
            >
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Adressat",
      renderHeader: () => <strong>Adressat</strong>,
      valueGetter: (params: any) => {
        return params.row.name;
      },
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "address",
      headerName: "Straße",
      renderHeader: () => <strong>Straße</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.addressLine1 : "";
      },
      type: "text",
      minWidth: 160,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "city",
      headerName: "Stadt",
      renderHeader: () => <strong>Stadt</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.city : "";
      },
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "postcode",
      headerName: "Postleitzahl",
      renderHeader: () => <strong>Postleitzahl</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.postcode : "";
      },
      type: "text",
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "country",
      headerName: "Land",
      renderHeader: () => <strong>Land</strong>,
      valueGetter: (params: any) => {
        return params.row.address.country
          ? params.row.address.country.nameDE
          : "";
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          id="outlined-basic"
          options={countries.map((option: { nameDE: string }) => option.nameDE)}
          onChange={(event, newName) => {
            const selectedItem: any = countries.find(
              (item: any) => item.nameDE === newName
            );
            if (selectedItem) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.nameDE,
              });
            }

            const { id } = params;

            // Access the current editing state from the apiRef
            const state = apiRef.current.state;
            const editRowsModel = state.editRows;

            // Get the original row and create an updatedRow
            const originalRow = rows.find((row) => row.id === id);
            const updatedRow = {
              ...originalRow, // Keep the original fields
              country: {
                id: selectedItem.id,
                nameDE: selectedItem.nameDE,
              },
              countryId: selectedItem.id,
            };

            // Merge any changes from the editRowsModel into updatedRow
            Object.keys(editRowsModel[id]).forEach((field) => {
              updatedRow[field] = editRowsModel[id][field].value;
            });

            // Deep comparison to avoid unnecessary state updates
            if (!deepEqual(updatedRow, originalRow)) {
              // Update the main rows state
              setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? updatedRow : row))
              );

              // Update the modifiedRows state
              setModifiedRows((prevModifiedRows) => {
                const updatedModifiedRows: any = [...prevModifiedRows];
                const index = updatedModifiedRows.findIndex(
                  (row: any) => row.id === id
                );
                if (index >= 0) {
                  updatedModifiedRows[index] = updatedRow;
                } else {
                  updatedModifiedRows.push(updatedRow);
                }
                return updatedModifiedRows;
              });
            }
          }}
          value={params.value}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-select-sender"
              label=""
              required
              size="small"
            />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "updatedAt",
      headerName: "Geändert",
      renderHeader: () => <strong>Geändert</strong>,
      type: "text",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return `${date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "modifiedUser",
      headerName: "Geändert von",
      renderHeader: () => <strong>Geändert von</strong>,
      valueGetter: (params: any) => {
        return params.row.updatedBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      renderHeader: () => <strong>Erstellt</strong>,
      type: "text",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return `${date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdUser",
      headerName: "Erstellt von",
      renderHeader: () => <strong>Erstellt von</strong>,
      valueGetter: (params: any) => {
        return params.row.createdBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const articleColumns: GridColDef[] = [
    {
      field: "ident",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      type: "text",
      minWidth: 80,
      headerAlign: "left",
      renderCell: (params) => {
        // Check if the cell is in edit mode
        if (
          !isBulkeditActive &&
          (props.user.role.permissions === "ADMIN" ||
            props.user.role.permissions === "WRITE")
        ) {
          return (
            <span
              style={{
                cursor: "pointer", // Makes the cell content appear clickable
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = "none"; // Remove underline when hover ends
              }}
              onClick={() => {
                const rowIndex = rows.findIndex(
                  (item) => item.id === params.id
                );
                editItem(rowIndex);
              }}
            >
              {params.value}
            </span>
          );
        } else {
          return (
            <span
              style={{
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
            >
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Artikel",
      renderHeader: () => <strong>Artikel</strong>,
      valueGetter: (params: any) => {
        return params.row.name;
      },
      type: "text",
      minWidth: 180,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "articleNumber",
      headerName: "Artikelnr.",
      renderHeader: () => <strong>Artikelnr.</strong>,
      valueGetter: (params: any) => {
        return params.row.articleNumber;
      },
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "updatedAt",
      headerName: "Geändert",
      renderHeader: () => <strong>Geändert</strong>,
      type: "dateTime",
      valueGetter: (params) => {
        return new Date(params.value);
        // const date = new Date(params.value);
        // return `${date
        //   .toLocaleDateString("en-GB", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //   })
        //   .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "modifiedUser",
      headerName: "Geändert von",
      renderHeader: () => <strong>Geändert von</strong>,
      valueGetter: (params: any) => {
        return params.row.updatedBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      renderHeader: () => <strong>Erstellt</strong>,
      type: "dateTime",
      valueGetter: (params) => {
        return new Date(params.value);
        // const date = new Date(params.value);
        // return `${date
        //   .toLocaleDateString("en-GB", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //   })
        //   .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdUser",
      headerName: "Erstellt von",
      renderHeader: () => <strong>Erstellt von</strong>,
      valueGetter: (params: any) => {
        return params.row.createdBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const carrierColumns: GridColDef[] = [
    {
      field: "ident",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      type: "text",
      minWidth: 80,
      headerAlign: "left",
      renderCell: (params) => {
        // Check if the cell is in edit mode
        if (
          !isBulkeditActive &&
          (props.user.role.permissions === "ADMIN" ||
            props.user.role.permissions === "WRITE")
        ) {
          return (
            <span
              style={{
                cursor: "pointer", // Makes the cell content appear clickable
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = "none"; // Remove underline when hover ends
              }}
              onClick={() => {
                const rowIndex = rows.findIndex(
                  (item) => item.id === params.id
                );
                editItem(rowIndex);
              }}
            >
              {params.value}
            </span>
          );
        } else {
          return (
            <span
              style={{
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
            >
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "name",
      headerName: "Spedition",
      renderHeader: () => <strong>Spedition</strong>,
      valueGetter: (params: any) => {
        return params.row.name;
      },
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "address",
      headerName: "Straße",
      renderHeader: () => <strong>Straße</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.addressLine1 : "";
      },
      type: "text",
      minWidth: 160,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "city",
      headerName: "Stadt",
      renderHeader: () => <strong>Stadt</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.city : "";
      },
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "postcode",
      headerName: "Postleitzahl",
      renderHeader: () => <strong>Postleitzahl</strong>,
      valueGetter: (params: any) => {
        return params.row.address ? params.row.address.postcode : "";
      },
      type: "text",
      minWidth: 100,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "country",
      headerName: "Land",
      renderHeader: () => <strong>Land</strong>,
      valueGetter: (params: any) => {
        return params.row.address.country
          ? params.row.address.country.nameDE
          : "";
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          id="outlined-basic"
          options={countries.map((option: { nameDE: string }) => option.nameDE)}
          onChange={(event, newName) => {
            const selectedItem: any = countries.find(
              (item: any) => item.nameDE === newName
            );
            if (selectedItem) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.nameDE,
              });
            }

            const { id } = params;

            // Access the current editing state from the apiRef
            const state = apiRef.current.state;
            const editRowsModel = state.editRows;

            // Get the original row and create an updatedRow
            const originalRow = rows.find((row) => row.id === id);
            const updatedRow = {
              ...originalRow, // Keep the original fields
              country: {
                id: selectedItem.id,
                nameDE: selectedItem.nameDE,
              },
              countryId: selectedItem.id,
            };

            // Merge any changes from the editRowsModel into updatedRow
            Object.keys(editRowsModel[id]).forEach((field) => {
              updatedRow[field] = editRowsModel[id][field].value;
            });

            // Deep comparison to avoid unnecessary state updates
            if (!deepEqual(updatedRow, originalRow)) {
              // Update the main rows state
              setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? updatedRow : row))
              );

              // Update the modifiedRows state
              setModifiedRows((prevModifiedRows) => {
                const updatedModifiedRows: any = [...prevModifiedRows];
                const index = updatedModifiedRows.findIndex(
                  (row: any) => row.id === id
                );
                if (index >= 0) {
                  updatedModifiedRows[index] = updatedRow;
                } else {
                  updatedModifiedRows.push(updatedRow);
                }
                return updatedModifiedRows;
              });
            }
          }}
          value={params.value}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-select-sender"
              label=""
              required
              size="small"
            />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "updatedAt",
      headerName: "Geändert",
      renderHeader: () => <strong>Geändert</strong>,
      type: "text",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return `${date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "modifiedUser",
      headerName: "Geändert von",
      renderHeader: () => <strong>Geändert von</strong>,
      valueGetter: (params: any) => {
        return params.row.updatedBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      renderHeader: () => <strong>Erstellt</strong>,
      type: "text",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return `${date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdUser",
      headerName: "Erstellt von",
      renderHeader: () => <strong>Erstellt von</strong>,
      valueGetter: (params: any) => {
        return params.row.createdBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const [countries, setCountries] = useState([]);

  const [columns, setColumns] = useState<Array<any>>([]);
  const [rows, setRows] = useState<Array<any>>([]);
  const [masterRows, setMasterRows] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [modifiedRows, setModifiedRows] = React.useState([]);
  const apiRef = useGridApiRef();

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );

  const editRowModesModel = rows.reduce((acc, row) => {
    acc[row.id] = { mode: GridRowModes.Edit };
    return acc;
  }, {});

  const viewRowModesModel = rows.reduce((acc, row) => {
    acc[row.id] = { mode: GridRowModes.View };
    return acc;
  }, {});

  const deepEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  };

  function startBulkedit() {
    setIsLoading(true); // Show loading indicator

    setTimeout(() => {
      // Set the rowModesModel state to put all rows in edit mode
      setRowModesModel(editRowModesModel);

      // Activate bulk edit mode and re-render the grid
      setIsBulkeditActive(true);
      setDataGridKey(dataGridKey + 1);

      // Hide loading indicator after processing
      setIsLoading(false);
    }, 0); // Process the operation immediately, but still allow the browser to update UI
  }

  function cancelBulkedit() {
    setRowModesModel(viewRowModesModel);

    setRows([...masterRows]);

    setIsBulkeditActive(false);
    setModifiedRows([]);
    setDataGridKey(dataGridKey + 1);
  }

  const handleRowEditStop = (params: any, event: any) => {
    event.defaultMuiPrevented = true;

    const { id } = params;
    const state = apiRef.current.state; // Correctly access the current state
    const editRowsModel = state.editRows; // Access the editing state

    const updatedRow = { ...rows.find((row) => row.id === id) };

    Object.keys(editRowsModel[id]).forEach((field) => {
      updatedRow[field] = editRowsModel[id][field].value;
    });

    const originalRow = rows.find((row: any) => row.id === id);

    if (!deepEqual(updatedRow, originalRow)) {
      setRows((prevRows) => {
        return prevRows.map((row) => (row.id === id ? updatedRow : row));
      });

      setModifiedRows((prevModifiedRows) => {
        const updatedModifiedRows: any = [...prevModifiedRows];
        const index = updatedModifiedRows.findIndex(
          (row: any) => row.id === id
        );
        if (index >= 0) {
          updatedModifiedRows[index] = updatedRow;
        } else {
          updatedModifiedRows.push(updatedRow);
        }
        return updatedModifiedRows;
      });
    }
  };

  const saveBulkedit = () => {
    if (props.scene === "customers") {
      let allRowsValid = true;

      modifiedRows.forEach((row: any, index) => {
        const { name, address, city, postcode, country } = row;

        let hasError = false;

        if (name === "") {
          errorToast(`Ungültiger Adressat (ID ${row.ident})`);
          hasError = true;
        }

        if (address === "" || !address) {
          errorToast(`Ungültige Straße (ID ${row.ident})`);
          hasError = true;
        }

        if (city === "" || !city) {
          errorToast(`Ungültige Stadt (ID ${row.ident})`);
          hasError = true;
        }

        if (postcode === "" || !postcode) {
          errorToast(`Ungültige Postleitzahl (ID ${row.ident})`);
          hasError = true;
        }

        if (country === "" || !country) {
          errorToast(`Ungültiges Land (ID ${row.ident})`);
          hasError = true;
        }

        if (
          address === "" ||
          city === "" ||
          postcode === "" ||
          country === "" ||
          !address ||
          !city ||
          !postcode ||
          !country
        ) {
          hasError = true;
        }

        if (hasError) {
          allRowsValid = false;
        }
      });

      // Only make the API call if all rows are valid
      if (allRowsValid) {
        const token = localStorage.getItem("empToken");

        fetch(
          `/api/customers/save-bulkedit`,
          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              modifiedRows: modifiedRows,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            getCustomers();
            setRowModesModel(viewRowModesModel);

            setIsBulkeditActive(false);
            setModifiedRows([]);
            setDataGridKey(dataGridKey + 1);

            toastListener("success");
          })
          .catch((err) => {
            console.error(err);
            toastListener("error");
          });
      } else {
        // Handle the case where some rows are invalid
        toastListener("error");
      }
    } else if (props.scene === "articles") {
      let allRowsValid = true;

      modifiedRows.forEach((row: any, index) => {
        const { articleNumber, name } = row;

        let hasError = false;

        if (articleNumber === "") {
          errorToast(`Ungültige Artikelnummer (ID ${row.ident})`);
          hasError = true;
        }

        if (name === "") {
          errorToast(`Ungültiger Artikelname (ID ${row.ident})`);
          hasError = true;
        }

        if (name === "" || name === "") {
          hasError = true;
        }

        if (hasError) {
          allRowsValid = false;
        }
      });

      // Only make the API call if all rows are valid
      if (allRowsValid) {
        const token = localStorage.getItem("empToken");

        fetch(
          `/api/articles/save-bulkedit`,
          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              modifiedRows: modifiedRows,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            getArticles();
            setRowModesModel(viewRowModesModel);

            setIsBulkeditActive(false);
            setModifiedRows([]);
            setDataGridKey(dataGridKey + 1);

            toastListener("success");
          })
          .catch((err) => {
            console.error(err);
            toastListener("error");
          });
      } else {
        // Handle the case where some rows are invalid
        toastListener("error");
      }
    } else if (props.scene === "carriers") {
      let allRowsValid = true;

      modifiedRows.forEach((row: any, index) => {
        const { name, address, city, postcode, country } = row;

        let hasError = false;

        if (name === "") {
          errorToast(`Ungültiger Spediteur (ID ${row.ident})`);
          hasError = true;
        }

        if (address === "" || !address) {
          console.log(address);
          errorToast(`Ungültige Straße (ID ${row.ident})`);
          hasError = true;
        }

        if (city === "" || !city) {
          errorToast(`Ungültige Stadt (ID ${row.ident})`);
          hasError = true;
        }

        if (postcode === "" || !postcode) {
          errorToast(`Ungültige Postleitzahl (ID ${row.ident})`);
          hasError = true;
        }

        if (country === "" || !country) {
          errorToast(`Ungültiges Land (ID ${row.ident})`);
          hasError = true;
        }

        if (
          address === "" ||
          city === "" ||
          postcode === "" ||
          country === "" ||
          !address ||
          !city ||
          !postcode ||
          !country
        ) {
          hasError = true;
        }

        if (hasError) {
          allRowsValid = false;
        }
      });

      // Only make the API call if all rows are valid
      if (allRowsValid) {
        const token = localStorage.getItem("empToken");

        fetch(
          `/api/carriers/save-bulkedit`,
          {
            method: "POST",
            headers: {
              authorization: "Bearer " + token,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              modifiedRows: modifiedRows,
            }),
          }
        )
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            getCarriers();
            setRowModesModel(viewRowModesModel);

            setIsBulkeditActive(false);
            setModifiedRows([]);
            setDataGridKey(dataGridKey + 1);

            toastListener("success");
          })
          .catch((err) => {
            console.error(err);
            toastListener("error");
          });
      } else {
        // Handle the case where some rows are invalid
        toastListener("error");
      }
    }
  };

  const filterSearch = (searchedVal: string) => {
    setSearchValue(searchedVal);

    const filteredRows = masterRows.filter((row: any) => {
      searchedVal = String(searchedVal.toLowerCase());

      let searchedIdent = "";
      let searchedArticleName = "";
      let searchedArticleNumber = "";
      let searchedCustomerName = "";
      let searchedCarrierName = "";
      let searchedAddressline1 = "";
      let searchedCity = "";
      let searchedPostcode = "";
      let searchedCountry = "";

      if (props.scene === "customers") {
        if (row.ident !== null) {
          searchedIdent = String(row.ident).toLowerCase();
        }
        if (row.name !== null) {
          searchedCustomerName = String(row.name).toLowerCase();
        }
        if (row.address.addressLine1 !== null) {
          searchedAddressline1 = String(row.address.addressLine1).toLowerCase();
        }
        if (row.address.city !== null) {
          searchedCity = String(row.address.city).toLowerCase();
        }
        if (row.address.postcode !== null) {
          searchedPostcode = String(row.address.postcode).toLowerCase();
        }
        if (row.address.country !== null) {
          searchedCountry = String(row.address.country.nameDE).toLowerCase();
        }
      } else if (props.scene === "articles") {
        if (row.ident !== null) {
          searchedIdent = String(row.ident).toLowerCase();
        }
        if (row.name !== null) {
          searchedArticleName = String(row.name).toLowerCase();
        }
        if (row.articleNumber !== null) {
          searchedArticleNumber = String(row.articleNumber).toLowerCase();
        }
      } else if (props.scene === "carriers") {
        if (row.ident !== null) {
          searchedIdent = String(row.ident).toLowerCase();
        }
        if (row.name !== null) {
          searchedCarrierName = String(row.name).toLowerCase();
        }
        if (row.address.addressLine1 !== null) {
          searchedAddressline1 = String(row.address.addressLine1).toLowerCase();
        }
        if (row.address.city !== null) {
          searchedCity = String(row.address.city).toLowerCase();
        }
        if (row.address.postcode !== null) {
          searchedPostcode = String(row.address.postcode).toLowerCase();
        }
        if (row.address.country !== null) {
          searchedCountry = String(row.address.country.nameDE).toLowerCase();
        }
      } else {
        if (row.ident !== null) {
          searchedIdent = String(row.ident).toLowerCase();
        }
      }

      return (
        searchedIdent.includes(searchedVal) ||
        searchedArticleName.includes(searchedVal) ||
        searchedArticleNumber.includes(searchedVal) ||
        searchedCustomerName.includes(searchedVal) ||
        searchedCarrierName.includes(searchedVal) ||
        searchedAddressline1.includes(searchedVal) ||
        searchedCity.includes(searchedVal) ||
        searchedPostcode.includes(searchedVal) ||
        searchedCountry.includes(searchedVal)
      );
    });

    setRows(filteredRows);
  };

  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [dataGridKey, setDataGridKey] = useState(0);
  const [gridFilter, setGridFilter] = useState(false);

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

  useEffect(() => {
    if (selectionModel.length !== 1) {
      setIsEditDisabled(true);
    } else {
      setIsEditDisabled(false);
    }

    if (selectionModel.length === 0) {
      setIsDeleteDisabled(true);
    } else {
      setIsDeleteDisabled(false);
    }
  }, [selectionModel]);

  const getCustomers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/customers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRows(data);
        setColumns(customerColumns);
        setMasterRows(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const getArticles = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/articles`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRows(data);
        setColumns(articleColumns);
        setMasterRows(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const getAddresses = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/addresses`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRows(data);
        setColumns(articleColumns);
        setMasterRows(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const getCarriers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/carriers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRows(data);
        setColumns(carrierColumns);
        setMasterRows(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  const getCountries = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/countries`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCountries(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    if (props.scene === "customers") {
      setIsLoading(true);
      getCustomers();
      getCountries();
    } else if (props.scene === "articles") {
      setIsLoading(true);
      getArticles();
    } else if (props.scene === "carriers") {
      setIsLoading(true);
      getCarriers();
      getCountries();
    }
  }, []);

  const newItem = () => {
    if (props.scene === "customers") {
      setCustomer({
        id: "",
        ident: "",
        name: "",
        addressId: "",
        addressLine1: "",
        city: "",
        postcode: "",
        countryId: "",
        countryName: "",
        modifiedTime: "",
        modifiedUser: "",
        createdTime: "",
        createdUser: "",
      });
    } else if (props.scene === "articles") {
      setArticle({
        id: "",
        ident: "",
        articleNumber: "",
        name: "",
        modifiedTime: "",
        modifiedUser: "",
        createdTime: "",
        createdUser: "",
      });
    } else if (props.scene === "carriers") {
      setCarrier({
        id: "",
        ident: "",
        name: "",
        addressId: "",
        addressLine1: "",
        city: "",
        postcode: "",
        countryId: "",
        countryName: "",
        modifiedTime: "",
        modifiedUser: "",
        createdTime: "",
        createdUser: "",
      });
    }

    setIsMasterDataModalVisible(true);
  };

  const editItem = (index: any) => {
    if (props.scene === "customers") {
      setCustomer({
        id: rows[index].id,
        ident: rows[index].ident,
        name: rows[index].name,
        addressId: rows[index].address.id,
        addressLine1: rows[index].address.addressLine1,
        city: rows[index].address.city,
        postcode: rows[index].address.postcode,
        countryId: rows[index].address.country.id,
        countryName: rows[index].address.country.nameDE,
        modifiedTime: rows[index].modifiedTime,
        modifiedUser: rows[index].modifiedUser,
        createdTime: rows[index].createdTime,
        createdUser: rows[index].createdUser,
      });
    } else if (props.scene === "articles") {
      setArticle({
        id: rows[index].id,
        ident: rows[index].ident,
        articleNumber: rows[index].articleNumber,
        name: rows[index].name,
        modifiedTime: rows[index].modifiedTime,
        modifiedUser: rows[index].modifiedUser,
        createdTime: rows[index].createdTime,
        createdUser: rows[index].createdUser,
      });
    } else if (props.scene === "carriers") {
      setCarrier({
        id: rows[index].id,
        ident: rows[index].ident,
        name: rows[index].name,
        addressId: rows[index].address.id,
        addressLine1: rows[index].address.addressLine1,
        city: rows[index].address.city,
        postcode: rows[index].address.postcode,
        countryId: rows[index].address.country.id,
        countryName: rows[index].address.country.nameDE,
        modifiedTime: rows[index].modifiedTime,
        modifiedUser: rows[index].modifiedUser,
        createdTime: rows[index].createdTime,
        createdUser: rows[index].createdUser,
      });
    }

    setIsMasterDataModalVisible(true);
  };

  const deleteItem = async (index: any) => {
    if (props.scene === "customers") {
      const token = localStorage.getItem("empToken");

      const deletePromise = fetch(
        `/api/customers/${rows[index].id}`,
        {
          method: "DELETE",
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === "in use") {
            toast.error(data.message);
          } else if (data.status === "success") {
            toast.success(data.message);
          }
        })
        .catch((err) => console.error(err));

      return deletePromise;
    } else if (props.scene === "articles") {
      const token = localStorage.getItem("empToken");

      const deletePromise = fetch(
        `/api/articles/${rows[index].id}`,
        {
          method: "DELETE",
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === "in use") {
            toast.error(data.message);
          } else if (data.status === "success") {
            toast.success(data.message);
          }
        })
        .catch((err) => console.error(err));

      return deletePromise;
    } else if (props.scene === "carriers") {
      const token = localStorage.getItem("empToken");

      const deletePromise = fetch(
        `/api/carriers/${rows[index].id}`,
        {
          method: "DELETE",
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === "in use") {
            toast.error(data.message);
          } else if (data.status === "success") {
            toast.success(data.message);
          }
        })
        .catch((err) => console.error(err));

      return deletePromise;
    }
  };

  const deleteSelectedItems = async () => {
    let selectedItems: any = [];

    for (const id of selectionModel) {
      let index = rows.findIndex((item) => item.id === id);

      selectedItems.push(index);
    }

    const deleteItemPromises: any = [];

    for (const selectedItem of selectedItems) {
      const deletePromise = deleteItem(selectedItem);
      deleteItemPromises.push(deletePromise);
    }

    await Promise.all(deleteItemPromises);

    if (props.scene === "customers") {
      getCustomers();
    } else if (props.scene === "articles") {
      getArticles();
    } else if (props.scene === "carriers") {
      getCarriers();
    }

    setIsDeletionModalVisible(false);
  };

  const simulateEnterPress = () => {
    const event = new KeyboardEvent("keydown", {
      key: "Enter",
      code: "Enter",
      keyCode: 13,
      which: 13,
      bubbles: true,
      cancelable: true,
    });

    let el: any = document.activeElement;

    el.dispatchEvent(event);
  };

  return (
    <div className="masterdataContainer">
      {isLoading === true ? (
        <div className="masterdataContainerLoading">
          <CircularProgress />
          <div className="masterdataContainerLoadingText">
            {props.scene === "customers" ? (
              <p>Adressbuch wird geladen ...</p>
            ) : props.scene === "articles" ? (
              <p>Artikel werden geladen ...</p>
            ) : props.scene === "addresses" ? (
              <p>Adressen werden geladen ...</p>
            ) : (
              <p>Speditionen werden geladen ...</p>
            )}
          </div>
        </div>
      ) : (
        <div className="masterdataContainerLoaded">
          <div className="masterdataContainerMenu">
            {isBulkeditActive ? (
              <div className="orderContainerMenuLeft">
                <div className="searchbar">
                  <TextField
                    id="search-bar"
                    className="searchbarText"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      filterSearch(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Suche..."
                    size="small"
                    value={searchValue}
                    InputProps={{
                      sx: { height: 33 },
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue === "" ? (
                            <SearchIcon />
                          ) : (
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => filterSearch("")}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<SaveRoundedIcon />}
                      onClick={() => saveBulkedit()}
                      sx={{ height: 33 }}
                      disabled={modifiedRows.length === 0}
                    >
                      Speichern
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      onClick={() => cancelBulkedit()}
                      sx={{ height: 33 }}
                    >
                      Rasteransicht beenden
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            ) : (
              <div className="orderContainerMenuLeft">
                <div className="searchbar">
                  <TextField
                    id="search-bar"
                    className="searchbarText"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      filterSearch(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Suche..."
                    size="small"
                    value={searchValue}
                    InputProps={{
                      sx: { height: 33 },
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue === "" ? (
                            <SearchIcon />
                          ) : (
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => filterSearch("")}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => newItem()}
                      sx={{ height: 33 }}
                    >
                      Neu
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<GridOnRoundedIcon />}
                      sx={{ height: 33 }}
                      onClick={() => startBulkedit()}
                    >
                      Rasteransicht
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                {props.user.role.permissions === "ADMIN" ? (
                  <IconButton size="small" onClick={openDropdown}>
                    <MoreHorizRoundedIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <div></div>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {props.user.role.permissions === "ADMIN" ? (
                    <MenuItem onClick={() => {}}>
                      <ExportGridData
                        gridApiRef={apiRef}
                        apiPath={props.scene}
                      />
                    </MenuItem>
                  ) : null}
                  {props.user.role.permissions === "ADMIN" && (
                    <MenuItem>
                      <Button
                        disabled={isDeleteDisabled}
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          handleClose();
                          setIsDeletionModalVisible(true);
                        }}
                        sx={{ height: 33 }}
                      >
                        Löschen
                      </Button>
                    </MenuItem>
                  )}
                </Menu>
              </div>
            )}
            <div className="masterdataContainerMenuRight">
              <div className="ordersContainerMenuAction">
                <TextField
                  id="outlined-select-view"
                  select
                  label="Ansicht"
                  value={activeView?.id}
                  size="small"
                  sx={{ width: "180px", height: 33 }}
                  InputProps={{
                    sx: { height: 33 },
                  }}
                >
                  {views.map((view) => (
                    <MenuItem
                      // sx={{ width: "320px" }}
                      key={view.id}
                      value={view.id}
                      onClick={() => changeViewHandler(view.id)}
                    >
                      {view.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <Tooltip title="Aktualisieren">
                <IconButton
                  onClick={
                    props.scene === "customers"
                      ? () => {
                          setIsLoading(true);
                          getCustomers();
                          toastListener("update");
                        }
                      : props.scene === "articles"
                        ? () => {
                            setIsLoading(true);
                            getArticles();
                            toastListener("update");
                          }
                        : props.scene === "addresses"
                          ? () => {
                              setIsLoading(true);
                              getAddresses();
                              toastListener("update");
                            }
                          : () => {
                              setIsLoading(true);
                              getCarriers();
                              toastListener("update");
                            }
                  }
                >
                  <SyncIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="masterdataContainerTable">
            {props.scene === "customers" ? (
              <DataGridPro
                key={dataGridKey}
                onFilterModelChange={(newModel) => {
                  changeFilterHandler(newModel);
                }}
                onSortModelChange={(newModel) => {
                  changeSortingHandler(newModel);
                }}
                rows={rows}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                columns={customerColumns}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  isBulkeditActive ? false : true
                }
                disableRowSelectionOnClick
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  cell: {
                    onChange: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                    onBlur: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                  },
                }}
                apiRef={apiRef}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[20, 50, 100]}
                pagination
                sx={
                  isBulkeditActive
                    ? {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiDataGrid-cell": {
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                    : {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={selectionModel}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onColumnVisibilityModelChange={(newModel, reason) => {
                  changeColumnVisibilityHandler(newModel);
                }}
                filterModel={controlledFilterModel}
                sortModel={controlledSortModel}
                columnVisibilityModel={controlledVisibleColumns}
              />
            ) : props.scene === "articles" ? (
              <DataGridPro
                key={dataGridKey}
                onFilterModelChange={(newModel) => {
                  changeFilterHandler(newModel);
                }}
                onSortModelChange={(newModel) => {
                  changeSortingHandler(newModel);
                }}
                rows={rows}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                columns={articleColumns}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  isBulkeditActive ? false : true
                }
                disableRowSelectionOnClick
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  cell: {
                    onChange: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                    onBlur: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                  },
                }}
                apiRef={apiRef}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[20, 50, 100]}
                pagination
                sx={
                  isBulkeditActive
                    ? {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiDataGrid-cell": {
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                    : {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={selectionModel}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onColumnVisibilityModelChange={(newModel, reason) => {
                  changeColumnVisibilityHandler(newModel);
                }}
                filterModel={controlledFilterModel}
                sortModel={controlledSortModel}
                columnVisibilityModel={controlledVisibleColumns}
              />
            ) : props.scene === "carriers" ? (
              <DataGridPro
                key={dataGridKey}
                onFilterModelChange={(newModel) => {
                  changeFilterHandler(newModel);
                }}
                onSortModelChange={(newModel) => {
                  changeSortingHandler(newModel);
                }}
                rows={rows}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                columns={carrierColumns}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  isBulkeditActive ? false : true
                }
                disableRowSelectionOnClick
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  cell: {
                    onChange: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                    onBlur: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                  },
                }}
                apiRef={apiRef}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[20, 50, 100]}
                pagination
                sx={
                  isBulkeditActive
                    ? {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiDataGrid-cell": {
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                    : {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={selectionModel}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onColumnVisibilityModelChange={(newModel, reason) => {
                  changeColumnVisibilityHandler(newModel);
                }}
                filterModel={controlledFilterModel}
                sortModel={controlledSortModel}
                columnVisibilityModel={controlledVisibleColumns}
              />
            ) : (
              <DataGridPro
                key={dataGridKey}
                onFilterModelChange={(newModel) => {
                  changeFilterHandler(newModel);
                }}
                onSortModelChange={(newModel) => {
                  changeSortingHandler(newModel);
                }}
                rows={rows}
                rowHeight={40}
                editMode="row"
                rowModesModel={rowModesModel}
                columns={customerColumns}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) =>
                  isBulkeditActive ? false : true
                }
                disableRowSelectionOnClick
                onRowEditStop={handleRowEditStop}
                slotProps={{
                  cell: {
                    onChange: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                    onBlur: (event: any) => {
                      setTimeout(() => {
                        simulateEnterPress();
                      }, 200);
                    },
                  },
                }}
                apiRef={apiRef}
                initialState={{
                  pagination: { paginationModel: { pageSize: 20 } },
                }}
                pageSizeOptions={[20, 50, 100]}
                pagination
                sx={
                  isBulkeditActive
                    ? {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiDataGrid-cell": {
                          borderRight: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                          borderBottom: "1px solid rgba(224, 224, 224, 1)",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                    : {
                        border: 0,
                        "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                          {
                            outline: "none",
                          },

                        "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                          {
                            cursor: "pointer",
                          },
                        ".priority": {},
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiSelect-select": {
                          fontSize: "0.875rem",
                        },
                      }
                }
                onRowSelectionModelChange={(newRowSelectionModel) => {
                  setSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={selectionModel}
                localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
                onColumnVisibilityModelChange={(newModel, reason) => {
                  changeColumnVisibilityHandler(newModel);
                }}
                filterModel={controlledFilterModel}
                sortModel={controlledSortModel}
                columnVisibilityModel={controlledVisibleColumns}
              />
            )}
          </div>
          <div className="masterdataContainerFooter">
            {gridFilter && (
              <div
                className="masterdataContainerFooterAction"
                onClick={() => {
                  setDataGridKey(dataGridKey + 1);
                  setGridFilter(false);
                }}
              >
                Filter zurücksetzen
              </div>
            )}
          </div>
          {isMasterDataModalVisible && (
            <MasterDataModal
              closeModal={setIsMasterDataModalVisible}
              toastListener={toastListener}
              scene={props.scene}
              item={
                props.scene === "customers"
                  ? customer
                  : props.scene === "articles"
                    ? article
                    : props.scene === "addresses"
                      ? address
                      : carrier
              }
              setItem={
                props.scene === "customers"
                  ? setCustomer
                  : props.scene === "articles"
                    ? setArticle
                    : props.scene === "addresses"
                      ? setAddress
                      : setCarrier
              }
              getItems={
                props.scene === "customers"
                  ? getCustomers
                  : props.scene === "articles"
                    ? getArticles
                    : props.scene === "addresses"
                      ? getAddresses
                      : getCarriers
              }
            />
          )}
          {isDeletionModalVisible && (
            <DeletionModal
              closeModal={setIsDeletionModalVisible}
              deleteItems={deleteSelectedItems}
            />
          )}
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default MasterData;
