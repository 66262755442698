import {
  GridColumnVisibilityModel,
  GridFilterModel,
  GridSortItem,
  GridSortModel,
} from "@mui/x-data-grid-pro";
import { GridApiCommunity } from "@mui/x-data-grid-pro/internals";
import {
  FILTER_SEARCH_PARAM,
  HIDDEN_COLUMNS_SEARCH_PARAM,
  SORTING_SEARCH_PARAM,
  useGridStateURL,
} from "./useGridStateURL";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { OrderGridView } from "../boards/orders/configurations/viewsConfig";

export const useGridViews = (viewsConfig: OrderGridView[]) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const {
    getGridStateFromUrl,
    persistGridColumnsStateInUrl,
    persistGridFilterStateInUrl,
    persistGridSortStateInUrl,
    clearGridStateInUrl,
    getGridViewIdFromUrl,
    setGridViewIdInUrl,
  } = useGridStateURL(searchParams, setSearchParams);

  //initial std views (maybe we persist and get from server in the future)
  const initialActiveViewId = getGridViewIdFromUrl();
  const initialGridStateInUrl = getGridStateFromUrl();

  const customViewIsActive =
    searchParams.has(FILTER_SEARCH_PARAM) ||
    searchParams.has(HIDDEN_COLUMNS_SEARCH_PARAM) ||
    searchParams.has(SORTING_SEARCH_PARAM);

  const [activeViewId, setActiveViewId] = useState<string | null>(
    initialActiveViewId
  );
  const [controlledFilterModel, setControlledFilterModel] = useState<
    GridFilterModel | undefined
  >(initialGridStateInUrl?.filterModel);
  const [controlledSortModel, setControlledSortModel] = useState<
    GridSortModel | undefined
  >(initialGridStateInUrl?.sortModel);
  const [controlledVisibleColumns, setControlledVisibleColumns] = useState<
    GridColumnVisibilityModel | undefined
  >(initialGridStateInUrl?.columnVisibilityModel);

  const views = viewsConfig.map((view, index) => {
    return {
      active:
        (activeViewId === view.id || (index == 0 && !activeViewId)) &&
        !customViewIsActive,
      id: view.id,
      displayName: view.displayName,
      filterModel: undefined,
      sortModel: undefined,
      columnVisibilityModel: view.hiddenColumns.reduce((acc, column) => {
        acc[column] = false;
        return acc;
      }, {} as GridColumnVisibilityModel),
    };
  });

  const customViewFromUrl = {
    active: customViewIsActive,
    id: "custom",
    displayName: "Benutzerdefiniert",
    columnVisibilityModel: initialGridStateInUrl?.columnVisibilityModel,
    filterModel: initialGridStateInUrl?.filterModel,
    sortModel: initialGridStateInUrl?.sortModel,
  };

  const changeFilterHandler = (
    filterModel: GridFilterModel | undefined,
    persistInUrl = true
  ) => {
    persistInUrl && persistGridFilterStateInUrl(filterModel);
    setControlledFilterModel(filterModel ? filterModel : { items: [] });
    if (filterModel != undefined) {
      setGridViewIdInUrl(undefined);
    }
  };
  const changeColumnVisibilityHandler = (
    columnVisibilityModel: GridColumnVisibilityModel,
    persistInUrl = true
  ) => {
    setControlledVisibleColumns(columnVisibilityModel);
    persistInUrl && persistGridColumnsStateInUrl(columnVisibilityModel);
    if (columnVisibilityModel != undefined) {
      setGridViewIdInUrl(undefined);
    }
  };
  const changeSortingHandler = (
    sortModel: GridSortModel | undefined,
    persistInUrl = true
  ) => {
    setControlledSortModel(sortModel ? sortModel : []);
    persistInUrl && persistGridSortStateInUrl(sortModel);
    if (sortModel != undefined) {
      setGridViewIdInUrl(undefined);
    }
  };
  const changeViewHandler = (viewId: string) => {
    if (viewId !== "custom") {
      clearGridStateInUrl();
      setActiveViewId(viewId);
      changeSortingHandler(undefined, false);
      changeFilterHandler(undefined, false);
      const activeView = views.find((v) => v.id === viewId);
      if (activeView) {
        changeColumnVisibilityHandler(activeView.columnVisibilityModel, false);
      }
    }
    setGridViewIdInUrl(viewId);
  };
  return {
    views: [...views, ...(customViewIsActive ? [customViewFromUrl] : [])],
    changeColumnVisibilityHandler,
    changeFilterHandler,
    changeSortingHandler,
    changeViewHandler,
    controlledFilterModel,
    controlledSortModel,
    controlledVisibleColumns,
  };
};

export interface GridView {
  active: boolean;
  id: string;
  displayName: string;
  filterModel?: GridFilterModel;
  sortItem?: GridSortItem;
  columnVisibilityModel: GridColumnVisibilityModel;
}
