import React, { useState, useEffect } from "react";
// MUI Components
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  deDE,
  GridRowModesModel,
  GridRowModes,
  useGridApiRef,
  GridRowParams,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";

// Custom Components
import OrderModal from "../../components/orderModal/orderModal";
import DeletionModal from "../../components/deletionModal/deletionModal";
import toast, { Toaster } from "react-hot-toast";
import dayjs, { Dayjs } from "dayjs";

// Styles
import "./orders.css";

// Icons
import SyncIcon from "@mui/icons-material/Sync";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import GridOnRoundedIcon from "@mui/icons-material/GridOnRounded";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";

import StarBorderRoundedIcon from "@mui/icons-material/StarBorderRounded";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import AlarmOnOutlinedIcon from "@mui/icons-material/AlarmOnOutlined";
import Inventory2RoundedIcon from "@mui/icons-material/Inventory2Rounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import ReceiptLongRoundedIcon from "@mui/icons-material/ReceiptLongRounded";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { UploadFileOutlined } from "@mui/icons-material";
import { ExportGridData } from "../../components/common/export/ExportGridData";
import { useGridViews } from "../../hooks/useGridViews";
import { ORDERS_VIEWS_CONFIG } from "./configurations/viewsConfig";

const Orders = (props: any) => {
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
  const [isDeletionModalVisible, setIsDeletionModalVisible] = useState(false);
  const [isBulkeditActive, setIsBulkeditActive] = useState(false);

  const [customers, setCustomers] = useState([]);
  const [carriers, setCarriers] = useState([]);
  const [articles, setArticles] = useState([]);

  const [intRegex, setIntRegex] = useState(/^[0-9]+$/);

  const getCustomers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/customers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCustomers(data);
      })
      .catch((err) => console.error(err));
  };

  const getCarriers = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/carriers`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setCarriers(data);
      })
      .catch((err) => console.error(err));
  };

  const getArticles = () => {
    const token = localStorage.getItem("empToken");

    fetch(`/api/articles`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setArticles(data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getCustomers();
    getCarriers();
    getArticles();
  }, []);

  const errorToast = (message: any) => toast.error(message);
  const successToast = (message: any) => toast.success(message);

  const toastListener = (message: any, info?: any) => {
    if (message === "success") {
      successToast("Daten gespeichert");
    } else if (message === "error") {
      errorToast("Etwas ist fehlgeschlagen");
    } else if (message === "deletion") {
      successToast("Löschen erfolgreich");
    } else if (message === "update") {
      successToast("Daten aktualisiert");
    } else if (message === "identChanged") {
      toast("Order ID hat sich geändert: " + info);
    }
  };

  const [order, setOrder] = useState({
    id: "",
    ident: "",
    orderState: "",
    lieferscheinNummer: "",
    article: "",
    amount: "",
    sender: "",
    recipient: "",
    carrier: "",
    sdBeleg: "",
    pspElement: "",
    versorgungsNummer: "",
    handlingUnitCount: "",
    handlingUnits: "",
    orderFiles: "",
    weightBrutto: "",
    packagingStage: "",
    specifics: "",
    orderDate: "",
    finishDate: "",
    pickUpDate: "",
    idGoodsIssued: "",
    modifiedTime: "",
    modifiedUser: "",
    createdTime: "",
    createdUser: "",
    isPriority: "",
  });

  const columns: GridColDef[] = [
    {
      field: "isPriority",
      headerName: "Prio",
      renderHeader: () => <strong>Prio</strong>,
      type: "boolean",
      width: 10,
      headerAlign: "left",
      align: "left",
      renderCell: (params) => (
        <div style={{ color: "#0066ff", paddingTop: "7px" }}>
          {params.row.isPriority === true && <WarningAmberRoundedIcon />}
        </div>
      ),
      editable: isBulkeditActive,
    },
    {
      field: "ident",
      headerName: "ID",
      renderHeader: () => <strong>ID</strong>,
      type: "number",
      minWidth: 80,
      headerAlign: "left",
      renderCell: (params) => {
        // Check if the cell is in edit mode
        if (
          !isBulkeditActive &&
          (props.user.role.permissions === "ADMIN" ||
            props.user.role.permissions === "WRITE")
        ) {
          return (
            <span
              style={{
                cursor: "pointer", // Makes the cell content appear clickable
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
              onMouseEnter={(e) => {
                e.currentTarget.style.textDecoration = "underline"; // Underline on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.textDecoration = "none"; // Remove underline when hover ends
              }}
              onClick={() => {
                const rowIndex = rows.findIndex(
                  (item) => item.id === params.id
                );
                editOrder(rowIndex);
              }}
            >
              {params.value}
            </span>
          );
        } else {
          return (
            <span
              style={{
                display: "inline-block",
                transition: "text-decoration 0.2s ease-in-out",
              }}
            >
              {params.value}
            </span>
          );
        }
      },
    },
    {
      field: "orderState",
      headerName: "Auftragsstatus",
      renderHeader: () => <strong>Auftragsstatus</strong>,
      minWidth: 190,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
      type: "singleSelect",
      valueOptions: [
        "Neu",
        "In Bearbeitung",
        "In Verpackung",
        "Rückmeldung Kunde",
        "Zur Abholung bereit",
        "Abgeholt",
        "In Abrechnung",
        "Abgerechnet",
        "Verladestopp",
      ],
      renderCell: (params) => (
        <div
          style={
            params.value === "Neu"
              ? {
                  width: "100%",
                  height: "100%",
                  color: "#A4ABB6",
                  display: "flex",
                  alignItems: "center",
                }
              : params.value === "In Bearbeitung"
                ? {
                    width: "100%",
                    height: "100%",
                    color: "#2DCCFF",
                    display: "flex",
                    alignItems: "center",
                  }
                : params.value === "In Verpackung"
                  ? {
                      width: "100%",
                      height: "100%",
                      color: "#3A63C8",
                      display: "flex",
                      alignItems: "center",
                    }
                  : params.value === "Rückmeldung Kunde"
                    ? {
                        width: "100%",
                        height: "100%",
                        color: "#61A8FF",
                        display: "flex",
                        alignItems: "center",
                      }
                    : params.value === "Zur Abholung bereit"
                      ? {
                          width: "100%",
                          height: "100%",
                          color: "#FCE83A",
                          display: "flex",
                          alignItems: "center",
                        }
                      : params.value === "Abgeholt"
                        ? {
                            width: "100%",
                            height: "100%",
                            color: "#FFB302",
                            display: "flex",
                            alignItems: "center",
                          }
                        : params.value === "In Abrechnung"
                          ? {
                              width: "100%",
                              height: "100%",
                              color: "#CEF000",
                              display: "flex",
                              alignItems: "center",
                            }
                          : params.value === "Abgerechnet"
                            ? {
                                width: "100%",
                                height: "100%",
                                color: "#56F000",
                                display: "flex",
                                alignItems: "center",
                              }
                            : params.value === "Verladestopp"
                              ? {
                                  width: "100%",
                                  height: "100%",
                                  color: "#FF6961",
                                  display: "flex",
                                  alignItems: "center",
                                }
                              : {
                                  width: "100%",
                                  height: "100%",
                                  color: "#FFFFFF00",
                                  display: "flex",
                                  alignItems: "center",
                                }
          }
        >
          {params.value === "Neu" ? (
            <Chip
              icon={
                <StarBorderRoundedIcon
                  style={{ color: "#95A5A6", fontSize: "18px" }}
                />
              }
              label="Neu"
              variant="outlined"
              sx={{
                color: "#95A5A6",
                borderColor: "#95A5A6",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "In Bearbeitung" ? (
            <Chip
              icon={
                <AccessTimeRoundedIcon
                  style={{ color: "#3498DB", fontSize: "18px" }}
                />
              }
              label="In Bearbeitung"
              variant="outlined"
              sx={{
                color: "#3498DB",
                borderColor: "#3498DB",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "In Verpackung" ? (
            <Chip
              icon={
                <Inventory2RoundedIcon
                  style={{ color: "#3A63C8", fontSize: "18px" }}
                />
              }
              label="In Verpackung"
              variant="outlined"
              sx={{
                color: "#3A63C8",
                borderColor: "#3A63C8",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "Rückmeldung Kunde" ? (
            <Chip
              icon={
                <QuestionAnswerOutlinedIcon
                  style={{ color: "#9B59B6", fontSize: "18px" }}
                />
              }
              label="Rückmeldung Kunde"
              variant="outlined"
              sx={{
                color: "#9B59B6",
                borderColor: "#9B59B6",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "Zur Abholung bereit" ? (
            <Chip
              icon={
                <AlarmOnOutlinedIcon
                  style={{ color: "#F1C40F", fontSize: "18px" }}
                />
              }
              label="Zur Abholung bereit"
              variant="outlined"
              sx={{
                color: "#F1C40F",
                borderColor: "#F1C40F",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "Abgeholt" ? (
            <Chip
              icon={
                <LocalShippingOutlinedIcon
                  style={{ color: "#E67E22", fontSize: "18px" }}
                />
              }
              label="Abgeholt"
              variant="outlined"
              sx={{
                color: "#E67E22",
                borderColor: "#E67E22",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "In Abrechnung" ? (
            <Chip
              icon={
                <ReceiptLongRoundedIcon
                  style={{ color: "#16A085", fontSize: "18px" }}
                />
              }
              label="In Abrechnung"
              variant="outlined"
              sx={{
                color: "#16A085",
                borderColor: "#16A085",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "Abgerechnet" ? (
            <Chip
              icon={
                <CheckCircleOutlineRoundedIcon
                  style={{ color: "#2ECC71", fontSize: "18px" }}
                />
              }
              label="Abgerechnet"
              variant="outlined"
              sx={{
                color: "#2ECC71",
                borderColor: "#2ECC71",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : params.value === "Verladestopp" ? (
            <Chip
              icon={
                <ErrorOutlineRoundedIcon
                  style={{ color: "#E74C3C", fontSize: "18px" }}
                />
              }
              label="Verladestopp"
              variant="outlined"
              sx={{
                color: "#E74C3C",
                borderColor: "#E74C3C",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          ) : (
            <Chip
              icon={
                <StarBorderRoundedIcon
                  style={{ color: "#95A5A6", fontSize: "18px" }}
                />
              }
              label="Neu"
              variant="outlined"
              sx={{
                color: "#95A5A6",
                borderColor: "#95A5A6",
                //borderWidth: "1.8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: "bold",
                height: "28px",
                borderRadius: "5px",
              }}
            />
          )}
        </div>
      ),
    },
    {
      field: "lieferscheinNummer",
      headerName: "Lieferscheinnr.",
      renderHeader: () => <strong>Lieferscheinnr.</strong>,
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "articleName",
      headerName: "Artikel",
      renderHeader: () => <strong>Artikel</strong>,
      valueGetter: (params: any) => {
        if (params.row.article) {
          return params.row.article.name;
        }
        return ""; // Return an empty string if article is not defined
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          options={articles.map((option: { name: string }) => option.name)}
          onChange={(event, newName) => {
            const selectedItem: any = articles.find(
              (article: any) => article.name === newName
            );
            if (selectedItem) {
              // Update the params to notify the grid about the new value
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.name,
              });
              // Optionally, update other fields based on this selection
              params.api.setEditCellValue({
                id: params.id,
                field: "articleNumber",
                value: selectedItem.articleNumber,
              });
            }

            const { id } = params;

            // Access the current editing state from the apiRef
            const state = apiRef.current.state;
            const editRowsModel = state.editRows;

            // Get the original row and create an updatedRow
            const originalRow = rows.find((row) => row.id === id);
            const updatedRow = {
              ...originalRow, // Keep the original fields
              article: {
                articleNumber: selectedItem.articleNumber,
                name: selectedItem.name,
              },
              articleId: selectedItem.id, // Update articleId
            };

            // Merge any changes from the editRowsModel into updatedRow
            Object.keys(editRowsModel[id]).forEach((field) => {
              updatedRow[field] = editRowsModel[id][field].value;
            });

            // Deep comparison to avoid unnecessary state updates
            if (!deepEqual(updatedRow, originalRow)) {
              // Update the main rows state
              setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? updatedRow : row))
              );

              // Update the modifiedRows state
              setModifiedRows((prevModifiedRows) => {
                const updatedModifiedRows: any = [...prevModifiedRows];
                const index = updatedModifiedRows.findIndex(
                  (row: any) => row.id === id
                );
                if (index >= 0) {
                  updatedModifiedRows[index] = updatedRow;
                } else {
                  updatedModifiedRows.push(updatedRow);
                }
                return updatedModifiedRows;
              });
            }
          }}
          value={params.value || ""}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField {...params} label="" required size="small" />
          )}
        />
      ),
      type: "text",
      minWidth: 180,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "articleNumber",
      headerName: "Artikelnr.",
      renderHeader: () => <strong>Artikelnr.</strong>,
      valueGetter: (params: any) => {
        if (params.row.article) {
          return params.row.article.articleNumber;
        }
        return ""; // Return an empty string if article is not defined
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          options={articles.map(
            (option: { articleNumber: string }) => option.articleNumber
          )}
          onChange={(event, newNumber) => {
            const selectedItem: any = articles.find(
              (article: any) => article.articleNumber === newNumber
            );
            if (selectedItem) {
              // Update the params to notify the grid about the new value
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.articleNumber,
              });
              // Update related fields (articleName) based on this selection
              params.api.setEditCellValue({
                id: params.id,
                field: "articleName",
                value: selectedItem.name,
              });

              const { id } = params;

              // Access the current editing state from the apiRef
              const state = apiRef.current.state;
              const editRowsModel = state.editRows;

              // Get the original row and create an updatedRow
              const originalRow = rows.find((row) => row.id === id);
              const updatedRow = {
                ...originalRow, // Keep the original fields
                article: {
                  articleNumber: selectedItem.articleNumber,
                  name: selectedItem.name,
                },
                articleId: selectedItem.id, // Update articleId
              };

              // Merge any changes from the editRowsModel into updatedRow
              Object.keys(editRowsModel[id]).forEach((field) => {
                updatedRow[field] = editRowsModel[id][field].value;
              });

              // Deep comparison to avoid unnecessary state updates
              if (!deepEqual(updatedRow, originalRow)) {
                // Update the main rows state
                setRows((prevRows) =>
                  prevRows.map((row) => (row.id === id ? updatedRow : row))
                );

                // Update the modifiedRows state
                setModifiedRows((prevModifiedRows) => {
                  const updatedModifiedRows: any = [...prevModifiedRows];
                  const index = updatedModifiedRows.findIndex(
                    (row: any) => row.id === id
                  );
                  if (index >= 0) {
                    updatedModifiedRows[index] = updatedRow;
                  } else {
                    updatedModifiedRows.push(updatedRow);
                  }
                  return updatedModifiedRows;
                });
              }
            }
          }}
          value={params.value || ""}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField {...params} label="" required size="small" />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "amount",
      headerName: "Anzahl",
      renderHeader: () => <strong>Anzahl</strong>,
      type: "number",
      minWidth: 70,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "senderName",
      headerName: "Absender",
      renderHeader: () => <strong>Absender</strong>,
      valueGetter: (params: any) => {
        if (params.row.sender) {
          return params.row.sender.name;
        }
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          id="outlined-basic"
          options={customers.map((option: { name: string }) => option.name)}
          onChange={(event, newName) => {
            const selectedItem: any = customers.find(
              (item: any) => item.name === newName
            );
            if (selectedItem) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.name,
              });
            }

            const { id } = params;

            // Access the current editing state from the apiRef
            const state = apiRef.current.state;
            const editRowsModel = state.editRows;

            // Get the original row and create an updatedRow
            const originalRow = rows.find((row) => row.id === id);
            const updatedRow = {
              ...originalRow, // Keep the original fields
              sender: {
                id: selectedItem.id,
                name: selectedItem.name,
              },
              senderId: selectedItem.id,
            };

            // Merge any changes from the editRowsModel into updatedRow
            Object.keys(editRowsModel[id]).forEach((field) => {
              updatedRow[field] = editRowsModel[id][field].value;
            });

            // Deep comparison to avoid unnecessary state updates
            if (!deepEqual(updatedRow, originalRow)) {
              // Update the main rows state
              setRows((prevRows) =>
                prevRows.map((row) => (row.id === id ? updatedRow : row))
              );

              // Update the modifiedRows state
              setModifiedRows((prevModifiedRows) => {
                const updatedModifiedRows: any = [...prevModifiedRows];
                const index = updatedModifiedRows.findIndex(
                  (row: any) => row.id === id
                );
                if (index >= 0) {
                  updatedModifiedRows[index] = updatedRow;
                } else {
                  updatedModifiedRows.push(updatedRow);
                }
                return updatedModifiedRows;
              });
            }
          }}
          value={params.value}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-select-sender"
              label=""
              required
              size="small"
            />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "recipientName",
      headerName: "Empfänger",
      renderHeader: () => <strong>Empfänger</strong>,
      valueGetter: (params: any) => {
        if (params.row.recipient) {
          return params.row.recipient.name;
        }
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          id="outlined-basic"
          options={customers.map((option: { name: string }) => option.name)}
          onChange={(event, newName) => {
            const selectedItem: any = customers.find(
              (item: any) => item.name === newName
            );
            if (selectedItem) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.name,
              });

              const { id } = params;

              // Access the current editing state from the apiRef
              const state = apiRef.current.state;
              const editRowsModel = state.editRows;

              // Get the original row and create an updatedRow
              const originalRow = rows.find((row) => row.id === id);
              const updatedRow = {
                ...originalRow, // Keep the original fields
                recipient: {
                  id: selectedItem.id,
                  name: selectedItem.name,
                },
                recipientId: selectedItem.id,
              };

              // Merge any changes from the editRowsModel into updatedRow
              Object.keys(editRowsModel[id]).forEach((field) => {
                updatedRow[field] = editRowsModel[id][field].value;
              });

              // Deep comparison to avoid unnecessary state updates
              if (!deepEqual(updatedRow, originalRow)) {
                // Update the main rows state
                setRows((prevRows) =>
                  prevRows.map((row) => (row.id === id ? updatedRow : row))
                );

                // Update the modifiedRows state
                setModifiedRows((prevModifiedRows) => {
                  const updatedModifiedRows: any = [...prevModifiedRows];
                  const index = updatedModifiedRows.findIndex(
                    (row: any) => row.id === id
                  );
                  if (index >= 0) {
                    updatedModifiedRows[index] = updatedRow;
                  } else {
                    updatedModifiedRows.push(updatedRow);
                  }
                  return updatedModifiedRows;
                });
              }
            }
          }}
          value={params.value}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-select-recipient"
              label=""
              required
              size="small"
            />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "carrierName",
      headerName: "Spedition",
      renderHeader: () => <strong>Spedition</strong>,
      valueGetter: (params: any) => {
        return params.row.carrier === null ? "" : params.row.carrier.name;
      },
      renderEditCell: (params) => (
        <Autocomplete
          sx={{
            "& .MuiInputBase-input": {
              fontSize: "0.875rem",
            },
          }}
          disablePortal
          fullWidth
          id="outlined-basic"
          options={carriers.map((option: { name: string }) => option.name)}
          onChange={(event, newName) => {
            const selectedItem: any = carriers.find(
              (item: any) => item.name === newName
            );
            if (selectedItem) {
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: selectedItem.name,
              });

              const { id } = params;

              // Access the current editing state from the apiRef
              const state = apiRef.current.state;
              const editRowsModel = state.editRows;

              // Get the original row and create an updatedRow
              const originalRow = rows.find((row) => row.id === id);
              const updatedRow = {
                ...originalRow, // Keep the original fields
                carrier: {
                  id: selectedItem.id,
                  name: selectedItem.name,
                },
                carrierId: selectedItem.id,
              };

              // Merge any changes from the editRowsModel into updatedRow
              Object.keys(editRowsModel[id]).forEach((field) => {
                updatedRow[field] = editRowsModel[id][field].value;
              });

              // Deep comparison to avoid unnecessary state updates
              if (!deepEqual(updatedRow, originalRow)) {
                // Update the main rows state
                setRows((prevRows) =>
                  prevRows.map((row) => (row.id === id ? updatedRow : row))
                );

                // Update the modifiedRows state
                setModifiedRows((prevModifiedRows) => {
                  const updatedModifiedRows: any = [...prevModifiedRows];
                  const index = updatedModifiedRows.findIndex(
                    (row: any) => row.id === id
                  );
                  if (index >= 0) {
                    updatedModifiedRows[index] = updatedRow;
                  } else {
                    updatedModifiedRows.push(updatedRow);
                  }
                  return updatedModifiedRows;
                });
              }
            }
          }}
          value={params.value}
          noOptionsText={"Keine Option..."}
          renderInput={(params) => (
            <TextField
              {...params}
              id="outlined-select-carrier"
              label=""
              size="small"
            />
          )}
        />
      ),
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "sdBeleg",
      headerName: "SD-Beleg",
      renderHeader: () => <strong>SD-Beleg</strong>,
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "pspElement",
      headerName: "PSP-Element",
      renderHeader: () => <strong>PSP-Element</strong>,
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "versorgungsNummer",
      headerName: "Versorgungsnr.",
      renderHeader: () => <strong>Versorgungsnr.</strong>,
      type: "text",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "handlingUnitCount",
      headerName: "Collianzahl",
      renderHeader: () => <strong>Collianzahl</strong>,
      type: "number",
      valueGetter: (params: any) => {
        return params.row.handlingUnits.length;
      },
      minWidth: 130,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "weightBrutto",
      headerName: "Gewicht ",
      renderHeader: () => <strong>Gewicht</strong>,
      type: "number",
      valueGetter: (params: any) => {
        if (params.row.handlingUnits.length > 0) {
          let bruttoWeight = 0;

          for (let i = 0; i < params.row.handlingUnits.length; i++) {
            const handlingUnitWeight = params.row.handlingUnits[i].weightBrutto;

            if (handlingUnitWeight !== "") {
              bruttoWeight = bruttoWeight + Number(handlingUnitWeight);
            }
          }

          return Number(bruttoWeight);
        } else {
          return 0;
        }
      },
      minWidth: 130,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "packagingStage",
      headerName: "Verpackungsstufe",
      renderHeader: () => <strong>Verpackungsstufe</strong>,
      width: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
      type: "singleSelect",
      valueOptions: ["H", "C", "B"],
    },
    {
      field: "specifics",
      headerName: "Besonderheiten",
      renderHeader: () => <strong>Besonderheiten</strong>,
      width: 130,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
      type: "singleSelect",
      valueOptions: ["ADR", "WvB", "IATA", "IMDG"],
    },
    {
      field: "orderDate",
      headerName: "Auftragsdatum",
      renderHeader: () => <strong>Auftragsdatum</strong>,
      type: "date",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      valueGetter: (params: any) => {
        if (params.value !== null) {
          return new Date(params.value); // Ensure the value is a Date object
        }
        return null;
      },
      valueFormatter: (params: any) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return params.value
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, ".");
        }
        return "";
      },
      editable: isBulkeditActive,
    },
    {
      field: "finishDate",
      headerName: "Auftrag Abschluss",
      renderHeader: () => <strong>Auftrag Abschluss</strong>,
      type: "date",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      valueGetter: (params: any) => {
        if (params.value !== null) {
          return new Date(params.value);
        }
        return null;
      },
      valueFormatter: (params: any) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return params.value
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, ".");
        }
        return "";
      },
      editable: isBulkeditActive,
    },
    {
      field: "pickUpDate",
      headerName: "Abgeholt am",
      renderHeader: () => <strong>Abgeholt am</strong>,
      type: "date",
      minWidth: 130,
      headerAlign: "left",
      align: "left",
      valueGetter: (params: any) => {
        if (params.value !== null) {
          return new Date(params.value); // Ensure the value is a Date object
        }
        return null;
      },
      valueFormatter: (params: any) => {
        if (params.value instanceof Date && !isNaN(params.value)) {
          return params.value
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, ".");
        }
        return "";
      },
      editable: isBulkeditActive,
    },
    {
      field: "idGoodsIssued",
      headerName: "ID-Warenausgangsbuchung",
      renderHeader: () => <strong>ID-Warenausgangsbuchung</strong>,
      type: "string",
      width: 200,
      headerAlign: "left",
      align: "left",
      editable: isBulkeditActive,
    },
    {
      field: "updatedAt",
      headerName: "Geändert",
      renderHeader: () => <strong>Geändert</strong>,
      type: "text",
      align: "left",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return `${date
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })
          .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
    },
    {
      field: "modifiedUser",
      headerName: "Geändert von",
      renderHeader: () => <strong>Geändert von</strong>,
      valueGetter: (params: any) => {
        return params.row.updatedBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdAt",
      headerName: "Erstellt",
      renderHeader: () => <strong>Erstellt</strong>,
      type: "dateTime",
      valueGetter: (params) => {
        const date = new Date(params.value);
        return date;
        // return `${date
        //   .toLocaleDateString("en-GB", {
        //     day: "2-digit",
        //     month: "2-digit",
        //     year: "numeric",
        //   })
        //   .replace(/\//g, ".")} ${date.toLocaleTimeString()}`;
      },
      minWidth: 160,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdUser",
      headerName: "Erstellt von",
      renderHeader: () => <strong>Erstellt von</strong>,
      valueGetter: (params: any) => {
        return params.row.createdBy.name;
      },
      type: "text",
      minWidth: 150,
      headerAlign: "left",
      align: "left",
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [rows, setRows] = useState<Array<any>>([]);
  const [masterRows, setMasterRows] = useState<Array<any>>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [modifiedRows, setModifiedRows] = React.useState([]);
  const apiRef = useGridApiRef();
  const {
    views,
    changeViewHandler,
    changeColumnVisibilityHandler,
    changeFilterHandler,
    changeSortingHandler,
    controlledFilterModel,
    controlledSortModel,
    controlledVisibleColumns,
  } = useGridViews(ORDERS_VIEWS_CONFIG);
  const activeView = views.find((view) => view.active);

  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const editRowModesModel = rows.reduce((acc, row) => {
    acc[row.id] = { mode: GridRowModes.Edit };
    return acc;
  }, {});

  const viewRowModesModel = rows.reduce((acc, row) => {
    acc[row.id] = { mode: GridRowModes.View };
    return acc;
  }, {});

  const deepEqual = (object1: any, object2: any) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (const key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  };

  function startBulkedit() {
    setIsLoading(true); // Show loading indicator

    setTimeout(() => {
      // Set the rowModesModel state to put all rows in edit mode
      setRowModesModel(editRowModesModel);

      // Activate bulk edit mode and re-render the grid
      setIsBulkeditActive(true);
      setDataGridKey(dataGridKey + 1);

      // Hide loading indicator after processing
      setIsLoading(false);
    }, 0); // Process the operation immediately, but still allow the browser to update UI
  }

  function cancelBulkedit() {
    setRowModesModel(viewRowModesModel);

    setRows([...masterRows]);

    setIsBulkeditActive(false);
    setModifiedRows([]);
    setDataGridKey(dataGridKey + 1);
  }

  const handleRowEditStop = (params: any, event: any) => {
    event.defaultMuiPrevented = true;

    const { id } = params;
    const state = apiRef.current.state; // Correctly access the current state
    const editRowsModel = state.editRows; // Access the editing state

    const updatedRow = { ...rows.find((row) => row.id === id) };

    Object.keys(editRowsModel[id]).forEach((field) => {
      updatedRow[field] = editRowsModel[id][field].value;
    });

    const originalRow = rows.find((row: any) => row.id === id);

    if (!deepEqual(updatedRow, originalRow)) {
      setRows((prevRows) => {
        return prevRows.map((row) => (row.id === id ? updatedRow : row));
      });

      setModifiedRows((prevModifiedRows) => {
        const updatedModifiedRows: any = [...prevModifiedRows];
        const index = updatedModifiedRows.findIndex(
          (row: any) => row.id === id
        );
        if (index >= 0) {
          updatedModifiedRows[index] = updatedRow;
        } else {
          updatedModifiedRows.push(updatedRow);
        }
        return updatedModifiedRows;
      });
    }
  };

  const saveBulkedit = () => {
    // Field check and regEx
    let allRowsValid = true;

    modifiedRows.forEach((row: any, index) => {
      const {
        lieferscheinNummer,
        orderDate,
        finishDate,
        pickUpDate,
        senderId,
        senderName,
        recipientId,
        recipientName,
        articleId,
        amount,
        sdBeleg,
      } = row;

      let hasError = false;

      if (lieferscheinNummer === "") {
        errorToast(`Ungültige Lieferscheinnr. (ID ${row.ident})`);
        hasError = true;
      }

      if (!orderDate || dayjs(orderDate).isValid() === false) {
        errorToast(`Ungültiges Auftragsdatum (ID ${row.ident})`);
        hasError = true;
      }

      if (finishDate !== null && dayjs(finishDate).isValid() === false) {
        errorToast(`Ungültiger Auftrag Abschluss (ID ${row.ident})`);
        hasError = true;
      }

      if (pickUpDate !== null && dayjs(pickUpDate).isValid() === false) {
        errorToast(`Ungültiges Abgeholt am Datum (ID ${row.ident})`);
        hasError = true;
      }

      if (!senderId || !senderName) {
        errorToast(`Ungültiger Absender (ID ${row.ident})`);
        hasError = true;
      }

      if (!recipientId || !recipientName) {
        errorToast(`Ungültiger Empfänger (ID ${row.ident})`);
        hasError = true;
      }

      if (!articleId) {
        errorToast(`Ungültiger Artikel (ID ${row.ident})`);
        hasError = true;
      }

      if (amount === "") {
        errorToast(`Ungültige Anzahl (ID ${row.ident})`);
        hasError = true;
      }

      const isSdBelegValid = intRegex.exec(sdBeleg);
      const isAmountValid = intRegex.exec(amount);

      if (!isSdBelegValid && sdBeleg !== null && sdBeleg !== "") {
        errorToast(`Ungültiger SD-Beleg (ID ${row.ident})`);
        hasError = true;
      }

      if (!isAmountValid) {
        errorToast(`Ungültige Anzahl (ID ${row.ident})`);
        hasError = true;
      }

      if (hasError) {
        allRowsValid = false;
      }
    });

    // Only make the API call if all rows are valid
    if (allRowsValid) {
      const token = localStorage.getItem("empToken");

      fetch(`/api/orders/save-bulkedit`, {
        method: "POST",
        headers: {
          authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          modifiedRows: modifiedRows,
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          getOrders();
          setRowModesModel(viewRowModesModel);

          setIsBulkeditActive(false);
          setModifiedRows([]);
          setDataGridKey(dataGridKey + 1);

          toastListener("success");
        })
        .catch((err) => {
          console.error(err);
          toastListener("error");
        });
    } else {
      // Handle the case where some rows are invalid
      toastListener("error");
    }
  };

  const filterSearch = (searchedVal: string) => {
    setSearchValue(searchedVal);

    const filteredRows = masterRows.filter((row: any) => {
      searchedVal = String(searchedVal.toLowerCase());

      let searchedIdent = "";
      let searchedLieferscheinNummer = "";
      let searchedOrderState = "";
      let searchedArticleName = "";
      let searchedarticleNumber = "";
      let searchedSenderName = "";
      let searchedRecipientName = "";
      let searchedCarrierName = "";
      let searchedOrderDate = "";
      let searchedFinishDate = "";
      let searchedPickUpDate = "";

      if (row.ident !== null) {
        searchedIdent = String(row.ident).toLowerCase();
      }
      if (row.lieferscheinNummer !== null) {
        searchedLieferscheinNummer = String(
          row.lieferscheinNummer
        ).toLowerCase();
      }
      if (row.orderState !== null) {
        searchedOrderState = String(row.orderState).toLowerCase();
      }
      if (row.article !== null) {
        searchedArticleName = String(row.article.name).toLowerCase();
      }
      if (row.article !== null) {
        searchedarticleNumber = String(row.article.articleNumber).toLowerCase();
      }
      if (row.sender !== null) {
        searchedSenderName = String(row.sender.name).toLowerCase();
      }
      if (row.recipient !== null) {
        searchedRecipientName = String(row.recipient.name).toLowerCase();
      }
      if (row.carrier !== null) {
        searchedCarrierName = String(row.carrier.name).toLowerCase();
      }
      if (row.orderDate !== null) {
        searchedOrderDate = String(row.orderDate).toLowerCase();
      }
      if (row.finishDate !== null) {
        searchedFinishDate = String(row.finishDate).toLowerCase();
      }
      if (row.pickUpDate !== null) {
        searchedPickUpDate = String(row.pickUpDate).toLowerCase();
      }

      return (
        searchedIdent.includes(searchedVal) ||
        searchedLieferscheinNummer.includes(searchedVal) ||
        searchedOrderState.includes(searchedVal) ||
        searchedArticleName.includes(searchedVal) ||
        searchedarticleNumber.includes(searchedVal) ||
        searchedSenderName.includes(searchedVal) ||
        searchedRecipientName.includes(searchedVal) ||
        searchedCarrierName.includes(searchedVal) /*||
        searchedOrderDate.includes(searchedVal) ||
        searchedFinishDate.includes(searchedVal) ||
        searchedPickUpDate.includes(searchedVal)*/
      );
    });

    setRows(filteredRows);
  };

  const [selectionModel, setSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [dataGridKey, setDataGridKey] = useState(0);

  const [isEditDisabled, setIsEditDisabled] = useState(true);
  const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

  useEffect(() => {
    if (selectionModel.length !== 1) {
      setIsEditDisabled(true);
    } else {
      setIsEditDisabled(false);
    }

    if (selectionModel.length === 0) {
      setIsDeleteDisabled(true);
    } else {
      setIsDeleteDisabled(false);
    }
  }, [selectionModel]);

  const getOrders = () => {
    const token = localStorage.getItem("empToken");

    let endPoint = "orders";

    if (props.scene === "openOrders") {
      endPoint = "orders/open";
    } else if (props.scene === "finishedOrders") {
      endPoint = "orders/finished";
    }

    fetch(`/api/${endPoint}`, {
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setRows(data);
        setMasterRows(data);
        setIsLoading(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getOrders();

    const intervalId = setInterval(() => {
      if (!isBulkeditActive) {
        getOrders();
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const newOrder = () => {
    setOrder({
      id: "",
      ident: "",
      orderState: "",
      lieferscheinNummer: "",
      article: "",
      amount: "",
      sender: "",
      recipient: "",
      carrier: "",
      sdBeleg: "",
      pspElement: "",
      versorgungsNummer: "",
      handlingUnitCount: "",
      handlingUnits: "",
      orderFiles: "",
      weightBrutto: "",
      packagingStage: "",
      specifics: "",
      orderDate: "",
      finishDate: "",
      pickUpDate: "",
      idGoodsIssued: "",
      modifiedTime: "",
      modifiedUser: "",
      createdTime: "",
      createdUser: "",
      isPriority: "",
    });

    setIsOrderModalVisible(true);
  };

  const editOrder = (index: any) => {
    setOrder({
      id: rows[index].id,
      ident: rows[index].ident,
      orderState: rows[index].orderState,
      lieferscheinNummer: rows[index].lieferscheinNummer,
      article: rows[index].article,
      amount: rows[index].amount,
      sender: rows[index].sender,
      recipient: rows[index].recipient,
      carrier: rows[index].carrier,
      sdBeleg: rows[index].sdBeleg,
      pspElement: rows[index].pspElement,
      versorgungsNummer: rows[index].versorgungsNummer,
      handlingUnitCount: rows[index].handlingUnitCount,
      handlingUnits: rows[index].handlingUnits,
      orderFiles: rows[index].orderFiles,
      weightBrutto: rows[index].weightBrutto,
      packagingStage: rows[index].packagingStage,
      specifics: rows[index].specifics,
      orderDate: rows[index].orderDate,
      finishDate: rows[index].finishDate,
      pickUpDate: rows[index].pickUpDate,
      idGoodsIssued: rows[index].idGoodsIssued,
      modifiedTime: rows[index].modifiedTime,
      modifiedUser: rows[index].modifiedUser,
      createdTime: rows[index].createdTime,
      createdUser: rows[index].createdUser,
      isPriority: rows[index].isPriority,
    });

    setIsOrderModalVisible(true);
  };

  const deleteOrder = async (index: any) => {
    const token = localStorage.getItem("empToken");

    const deletePromise = fetch(
      `/api/orders/${rows[index].id}`,
      {
        method: "DELETE",
        headers: {
          authorization: "Bearer " + token,
        },
      }
    );

    return deletePromise;
  };

  const deleteSelectedOrders = async () => {
    let selectedOrders: any = [];

    for (const id of selectionModel) {
      let index = rows.findIndex((item) => item.id === id);

      selectedOrders.push(index);
    }

    const deleteOrderPromises: any = [];

    for (const selectedOrder of selectedOrders) {
      const deletePromise = deleteOrder(selectedOrder);
      deleteOrderPromises.push(deletePromise);
    }

    await Promise.all(deleteOrderPromises);

    getOrders();
    setIsDeletionModalVisible(false);
    toastListener("deletion");
  };

  const downloadOrderInvoiceXlsx = async () => {
    const token = localStorage.getItem("empToken");
    fetch(
      `/api/orders/exports/excel/accounting-overview`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((blob) => {
        console.log(`Received XLSX Data Length: ${blob.byteLength}`);
        try {
          const xlsxData = new Blob([blob]);
          const xlsxUrl = URL.createObjectURL(xlsxData);
          // Create a link element to trigger the download
          const a = document.createElement("a");
          a.href = xlsxUrl;
          let xlsxName = "EMP_Abrechnung.xlsx";
          a.download = xlsxName;
          document.body.appendChild(a);
          a.click();
          // Clean up resources
          URL.revokeObjectURL(xlsxUrl);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error fetching or downloading XLSX:", error);
        }
      })
      .catch((err) => console.error(err));
  };

  const downloadOrderCoverPdf = async (order: any) => {
    const orderId = order.id;
    const token = localStorage.getItem("empToken");
    fetch(
      `/api/orders/exports/pdf/order-cover/${orderId}`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((blob) => {
        console.log(`Received PDF Data Length: ${blob.byteLength}`);
        try {
          const pdfData = new Blob([blob]);
          const pdfUrl = URL.createObjectURL(pdfData);
          // Create a link element to trigger the download
          const a = document.createElement("a");
          a.href = pdfUrl;
          let pdfName =
            "Lieferscheindeckblatt_" +
            order.lieferscheinNummer +
            "_" +
            order.ident +
            ".pdf";
          a.download = pdfName;
          document.body.appendChild(a);
          a.click();
          // Clean up resources
          URL.revokeObjectURL(pdfUrl);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error fetching or downloading PDF:", error);
        }
      })
      .catch((err) => console.error(err));
  };

  const downloadSelectedOrdercovers = async () => {
    let selectedOrders: any = [];

    for (const id of selectionModel) {
      let index = rows.findIndex((item) => item.id === id);

      selectedOrders.push(rows[index]);
    }

    const printOrderPromises: any = [];

    for (const selectedOrder of selectedOrders) {
      const printPromise = downloadOrderCoverPdf(selectedOrder);
      printOrderPromises.push(printPromise);
    }

    await Promise.all(printOrderPromises);
  };

  const uploadKontierungCsv = (event: any) => {
    let uploadToast = toast.loading("Datei hochladen...");
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append("file", selectedFile);
    const token = localStorage.getItem("empToken");
    fetch(`/api/orders/upload-kontierung`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        handleClose();
        toast.success(data.message, {
          id: uploadToast,
        });
      })
      .catch((err) => console.error(err));
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function openDropdown(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  const downloadLaborNotePdf = async (order: any) => {
    const orderId = order.id;
    const token = localStorage.getItem("empToken");
    fetch(
      `/api/orders/exports/pdf/labor-note/${orderId}`,
      {
        headers: {
          authorization: "Bearer " + token,
        },
      }
    )
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((blob) => {
        console.log(`Received PDF Data Length: ${blob.byteLength}`);
        try {
          const pdfData = new Blob([blob]);
          const pdfUrl = URL.createObjectURL(pdfData);
          // Create a link element to trigger the download
          const a = document.createElement("a");
          a.href = pdfUrl;
          let pdfName =
            "Arbeitsschein_" +
            order.lieferscheinNummer +
            "_" +
            order.ident +
            ".pdf";
          a.download = pdfName;
          document.body.appendChild(a);
          a.click();
          // Clean up resources
          URL.revokeObjectURL(pdfUrl);
          document.body.removeChild(a);
        } catch (error) {
          console.error("Error fetching or downloading PDF:", error);
        }
      })
      .catch((err) => console.error(err));
  };

  const downloadSelectedLaborNotes = async () => {
    let selectedOrders: any = [];

    for (const id of selectionModel) {
      let index = rows.findIndex((item) => item.id === id);

      selectedOrders.push(rows[index]);
    }

    const printOrderPromises: any = [];

    for (const selectedOrder of selectedOrders) {
      const printPromise = downloadLaborNotePdf(selectedOrder);
      printOrderPromises.push(printPromise);
    }

    await Promise.all(printOrderPromises);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const simulateEnterPress = () => {
    const event = new KeyboardEvent("keydown", {
      key: "Enter",
      code: "Enter",
      keyCode: 13,
      which: 13,
      bubbles: true,
    });

    let el: any = document.activeElement;

    el.dispatchEvent(event);
  };
  return (
    <div className="ordersContainer">
      {isLoading === true ? (
        <div className="ordersContainerLoading">
          <CircularProgress />
          <div className="ordersContainerLoadingText">
            Aufträge werden geladen ...
          </div>
        </div>
      ) : (
        <div className="ordersContainerLoaded">
          <div className="ordersContainerMenu">
            {isBulkeditActive ? (
              <div className="orderContainerMenuLeft">
                <div className="searchbar">
                  <TextField
                    id="search-bar"
                    className="searchbarText"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      filterSearch(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Suche..."
                    size="small"
                    value={searchValue}
                    InputProps={{
                      sx: { height: 33 },
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue === "" ? (
                            <SearchIcon />
                          ) : (
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => filterSearch("")}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<SaveRoundedIcon />}
                      onClick={() => saveBulkedit()}
                      sx={{ height: 33 }}
                      disabled={modifiedRows.length === 0}
                    >
                      Speichern
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      onClick={() => cancelBulkedit()}
                      sx={{ height: 33 }}
                    >
                      Rasteransicht beenden
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            ) : (
              <div className="orderContainerMenuLeft">
                <div className="searchbar">
                  <TextField
                    id="search-bar"
                    className="searchbarText"
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      filterSearch(e.target.value);
                    }}
                    variant="outlined"
                    placeholder="Suche..."
                    size="small"
                    value={searchValue}
                    InputProps={{
                      sx: { height: 33 },
                      endAdornment: (
                        <InputAdornment position="end">
                          {searchValue === "" ? (
                            <SearchIcon />
                          ) : (
                            <CloseIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => filterSearch("")}
                            />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => newOrder()}
                      sx={{ height: 33 }}
                    >
                      Neu
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                <div className="ordersContainerMenuAction">
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <Button
                      variant="outlined"
                      startIcon={<GridOnRoundedIcon />}
                      sx={{ height: 33 }}
                      onClick={() => startBulkedit()}
                    >
                      Rasteransicht
                    </Button>
                  ) : (
                    <div></div>
                  )}
                </div>
                {props.user.role.permissions === "ADMIN" ||
                props.user.role.permissions === "WRITE" ? (
                  <IconButton size="small" onClick={openDropdown}>
                    <MoreHorizRoundedIcon fontSize="small" />
                  </IconButton>
                ) : (
                  <div></div>
                )}
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <MenuItem onClick={handleClose}>
                      <Button
                        disabled={isDeleteDisabled}
                        variant="outlined"
                        startIcon={<PrintIcon />}
                        sx={{ height: 33 }}
                        onClick={() => downloadSelectedOrdercovers()}
                      >
                        Deckblatt
                      </Button>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}
                  {props.user.role.permissions === "ADMIN" ||
                  props.user.role.permissions === "WRITE" ? (
                    <MenuItem onClick={handleClose}>
                      <Button
                        disabled={isDeleteDisabled}
                        variant="outlined"
                        startIcon={<PrintIcon />}
                        sx={{ height: 33 }}
                        onClick={() => downloadSelectedLaborNotes()}
                      >
                        Arbeitsschein
                      </Button>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}
                  {props.user.role.permissions === "ADMIN" ? (
                    <MenuItem onClick={handleClose}>
                      <Button
                        variant="outlined"
                        startIcon={<ReceiptOutlinedIcon />}
                        sx={{ height: 33 }}
                        onClick={() => downloadOrderInvoiceXlsx()}
                      >
                        Abrechnung
                      </Button>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}
                  {props.user.role.permissions === "ADMIN" ? (
                    <MenuItem onClick={() => {}}>
                      <ExportGridData
                        gridApiRef={apiRef}
                        apiPath="orders"
                        {...(props.scene == "openOrders"
                          ? { orderStatus: "Open" }
                          : { orderStatus: "Closed" })}
                      />
                    </MenuItem>
                  ) : null}
                  {props.user.role.permissions === "ADMIN" ? (
                    <MenuItem>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileOutlined />}
                        sx={{ height: 33 }}
                      >
                        <input
                          type="file"
                          accept=".csv"
                          hidden
                          onChange={uploadKontierungCsv}
                        />
                        Kontierung
                      </Button>
                    </MenuItem>
                  ) : (
                    <div></div>
                  )}
                  {props.user.role.permissions === "ADMIN" && (
                    <MenuItem>
                      <Button
                        disabled={isDeleteDisabled}
                        variant="outlined"
                        color="error"
                        startIcon={<DeleteIcon />}
                        onClick={() => {
                          handleClose();
                          setIsDeletionModalVisible(true);
                        }}
                        sx={{ height: 33 }}
                      >
                        Löschen
                      </Button>
                    </MenuItem>
                  )}
                </Menu>
              </div>
            )}
            <div className="orderContainerMenuRight">
              <div className="ordersContainerMenuAction">
                <TextField
                  id="outlined-select-view"
                  select
                  label="Ansicht"
                  value={activeView?.id}
                  size="small"
                  sx={{ width: "180px", height: 33 }}
                  InputProps={{
                    sx: { height: 33 },
                  }}
                >
                  {views.map((view) => (
                    <MenuItem
                      // sx={{ width: "320px" }}
                      key={view.id}
                      value={view.id}
                      onClick={() => changeViewHandler(view.id)}
                    >
                      {view.displayName}
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <Tooltip title="Aktualisieren">
                <IconButton
                  onClick={() => {
                    setIsLoading(true);
                    getOrders();
                    toastListener("update");
                  }}
                >
                  <SyncIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="ordersContainerTable">
            <DataGridPro
              key={dataGridKey}
              onFilterModelChange={(newModel) => {
                changeFilterHandler(newModel);
              }}
              onSortModelChange={(newModel) => {
                changeSortingHandler(newModel);
              }}
              rows={rows}
              rowHeight={40}
              columns={columns}
              editMode="row"
              rowModesModel={rowModesModel}
              checkboxSelection
              isRowSelectable={(params: GridRowParams) =>
                isBulkeditActive ? false : true
              }
              disableRowSelectionOnClick
              onRowEditStop={handleRowEditStop}
              slotProps={{
                cell: {
                  onChange: (event: any) => {
                    setTimeout(() => {
                      simulateEnterPress();
                    }, 200);
                  },
                  onBlur: (event: any) => {
                    setTimeout(() => {
                      simulateEnterPress();
                    }, 200);
                  },
                },
              }}
              apiRef={apiRef}
              initialState={{
                pagination: { paginationModel: { pageSize: 20 } },
              }}
              pageSizeOptions={[20, 50, 100]}
              pagination
              getRowClassName={(params) => {
                const classes = [];

                if (params.row.isPriority) {
                  classes.push("priority");
                }

                return classes.join(" ");
              }}
              sx={
                isBulkeditActive
                  ? {
                      border: 0,
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                        {
                          cursor: "pointer",
                        },
                      ".priority": {},
                      "& .MuiDataGrid-cell": {
                        borderRight: "1px solid rgba(224, 224, 224, 1)",
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.875rem",
                      },
                    }
                  : {
                      border: 0,
                      "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus":
                        {
                          outline: "none",
                        },

                      "& .MuiDataGrid-columnHeader:hover, & .MuiDataGrid-cell:hover":
                        {
                          cursor: "pointer",
                        },
                      ".priority": {},
                      "& .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "& .MuiSelect-select": {
                        fontSize: "0.875rem",
                      },
                    }
              }
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={selectionModel}
              localeText={deDE.components.MuiDataGrid.defaultProps.localeText}
              onColumnVisibilityModelChange={(newModel, reason) => {
                changeColumnVisibilityHandler(newModel);
              }}
              filterModel={controlledFilterModel}
              sortModel={controlledSortModel}
              columnVisibilityModel={controlledVisibleColumns}
            />
          </div>
          {isOrderModalVisible && (
            <OrderModal
              closeModal={setIsOrderModalVisible}
              toastListener={toastListener}
              order={order}
              setOrder={setOrder}
              getOrders={getOrders}
              downloadOrderCoverPdf={downloadOrderCoverPdf}
              downloadLaborNotePdf={downloadLaborNotePdf}
            />
          )}
          {isDeletionModalVisible && (
            <DeletionModal
              closeModal={setIsDeletionModalVisible}
              deleteItems={deleteSelectedOrders}
            />
          )}
        </div>
      )}
      <Toaster />
    </div>
  );
};

export default Orders;
