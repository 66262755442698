import { useMutation } from "@tanstack/react-query";
import { api } from "../lib/axios";
import { GridApiPro } from "@mui/x-data-grid-pro";
import { MutableRefObject } from "react";
export const useGridViewExport = (
  apiPath: string,
  gridApiRef: MutableRefObject<GridApiPro> | null | undefined,
  orderStatus?: "Open" | "Closed"
) => {
  const exportMutation = useMutation({
    mutationFn: async () => {
      if (!gridApiRef?.current) {
        return;
      }
      const exportedState = gridApiRef?.current?.exportState();
      const { filter, columns, sorting } = exportedState || {};
      const backendFilter =
        filter &&
        filter.filterModel &&
        filter.filterModel.items &&
        filter.filterModel.items.length > 0
          ? {
              logicOperator: filter.filterModel.logicOperator,
              items: filter.filterModel.items,
            }
          : null;
      const response = await api.post(
        `/api/${apiPath}/exports/excel/view`,
        {
          backendFilter,
          backendColumnsVisibility: columns?.columnVisibilityModel,
          backendSort: sorting?.sortModel,
          ...(orderStatus && { orderStatus }),
        },
        { responseType: "arraybuffer" }
      );
      const xlsxData = new Blob([response.data]);
      const xlsxUrl = URL.createObjectURL(xlsxData);
      const a = document.createElement("a");
      a.href = xlsxUrl;
      let xlsxName =
        extractFilename(response.headers["content-disposition"]) ||
        "EMP_Abrechnung.xlsx";
      a.download = xlsxName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(xlsxUrl);
      document.body.removeChild(a);
      return response.data;
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return { exportMutation };
};

const extractFilename = (contentDispositionHeader: string) => {
  if (!contentDispositionHeader) return null;
  const match = contentDispositionHeader.match(/filename\*?=([^;]+)/);
  if (match) {
    let filename = match[1].trim();
    if (filename.startsWith("UTF-8''")) {
      filename = decodeURIComponent(filename.replace("UTF-8''", ""));
    }
    return filename.replace(/^"|"$/g, "");
  }
  return null;
};
