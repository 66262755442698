import Axios, { InternalAxiosRequestConfig } from "axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const api = Axios.create({
  baseURL: backendUrl || "",
});

api.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
  const token = localStorage.getItem("empToken");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
