import { LicenseInfo } from "@mui/x-license-pro";
import { ThemeProvider } from "@mui/material";
import { appTheme } from "./theme";

// Styles
import "./App.css";

// Scenes
import Login from "./scenes/login/login";
import Dashboard from "./scenes/dashboard/dashboard";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();
function App() {
  const token = localStorage.getItem("empToken");

  return (
    <ThemeProvider theme={appTheme}>
      <QueryClientProvider client={queryClient}>
        <div className="appWrapper">
          <div className="app">{!token ? <Login /> : <Dashboard />}</div>
        </div>
      </QueryClientProvider>
    </ThemeProvider>
  );
}
LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY || "");
export default App;
